import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Tabs = ({
  setweekActive,
  weekActive,
  setDynamicURL,
  deadlineType,
  activatedWeeks,
}: any) => {
  const { t, i18n } = useTranslation()
  const [languageDetect, setlanguageDetect] = useState<string>('')

  useEffect(() => {
    if (i18n.language) {
      setlanguageDetect(i18n.language)
    }
  }, [i18n.language])

  return (
    <div className="competition__terms__tabs">
      <button
        disabled={
          activatedWeeks.find((n: any) => n === '/share') ? false : true
        }
        className={`competition__terms__tab ${
          weekActive === 1 || deadlineType === '/share' ? 'active' : ''
        } ${languageDetect === 'uzk' ? 'change__font__medium' : ''} ${
          activatedWeeks.find((n: any) => n === '/share') ? 'passed' : ''
        }`}
        onClick={() => {
          setDynamicURL('/share')
          setweekActive(1)
        }}
      >
        1 {t('contest.week')}
      </button>
      <button
        disabled={
          activatedWeeks.find((n: any) => n === '/invite') ? false : true
        }
        className={`competition__terms__tab ${
          weekActive === 2 || deadlineType === '/invite' ? 'active' : ''
        } ${languageDetect === 'uzk' ? 'change__font__medium' : ''} ${
          activatedWeeks.find((n: any) => n === '/invite') ? 'passed' : ''
        }`}
        onClick={() => {
          setDynamicURL('/invite')
          setweekActive(2)
        }}
      >
        2 {t('contest.week')}
      </button>
      <button
        disabled={
          activatedWeeks.find((n: any) => n === '/payment/count') ? false : true
        }
        className={`competition__terms__tab ${
          weekActive === 3 || deadlineType === '/payment/count' ? 'active' : ''
        } ${languageDetect === 'uzk' ? 'change__font__medium' : ''} ${
          activatedWeeks.find((n: any) => n === '/payment/count')
            ? 'passed'
            : ''
        }`}
        onClick={() => {
          setDynamicURL('/payment/count')
          setweekActive(3)
        }}
      >
        3 {t('contest.week')}
      </button>
      <button
        disabled={
          activatedWeeks.find((n: any) => n === '/payment/sum') ? false : true
        }
        className={`competition__terms__tab ${
          weekActive === 4 || deadlineType === '/payment/sum' ? 'active' : ''
        } ${languageDetect === 'uzk' ? 'change__font__medium' : ''} ${
          activatedWeeks.find((n: any) => n === '/payment/sum') ? 'passed' : ''
        }`}
        onClick={() => {
          setDynamicURL('/payment/sum')
          setweekActive(4)
        }}
      >
        4 {t('contest.week')}
      </button>
      <button
        disabled={
          activatedWeeks.find((n: any) => n === '/receive/count') ? false : true
        }
        className={`competition__terms__tab ${
          weekActive === 5 || deadlineType === '/receive/count' ? 'active' : ''
        } ${languageDetect === 'uzk' ? 'change__font__medium' : ''} ${
          activatedWeeks.find((n: any) => n === '/receive/count')
            ? 'passed'
            : ''
        }`}
        onClick={() => {
          setDynamicURL('/receive/count')
          setweekActive(5)
        }}
      >
        5 {t('contest.week')}
      </button>
      <button
        disabled={
          activatedWeeks.find((n: any) => n === '/receive/sum') ? false : true
        }
        className={`competition__terms__tab ${
          weekActive === 6 || deadlineType === '/receive/sum' ? 'active' : ''
        } ${languageDetect === 'uzk' ? 'change__font__medium' : ''} ${
          activatedWeeks.find((n: any) => n === '/receive/sum') ? 'passed' : ''
        }`}
        onClick={() => {
          setDynamicURL('/receive/sum')
          setweekActive(6)
        }}
      >
        6 {t('contest.week')}
      </button>
      <button
        disabled={
          activatedWeeks.find((n: any) => n === '/remit/count') ? false : true
        }
        className={`competition__terms__tab ${
          weekActive === 7 || deadlineType === '/remit/count' ? 'active' : ''
        } ${languageDetect === 'uzk' ? 'change__font__medium' : ''} ${
          activatedWeeks.find((n: any) => n === '/remit/count') ? 'passed' : ''
        }`}
        onClick={() => {
          setDynamicURL('/remit/count')
          setweekActive(7)
        }}
      >
        7 {t('contest.week')}
      </button>
      <button
        disabled={
          activatedWeeks.find((n: any) => n === '/remit/sum') ? false : true
        }
        className={`competition__terms__tab ${
          weekActive === 8 || deadlineType === '/remit/sum' ? 'active' : ''
        } ${languageDetect === 'uzk' ? 'change__font__medium' : ''} ${
          activatedWeeks.find((n: any) => n === '/remit/sum') ? 'passed' : ''
        }`}
        onClick={() => {
          setDynamicURL('/remit/sum')
          setweekActive(8)
        }}
      >
        8 {t('contest.week')}
      </button>
    </div>
  )
}

export default Tabs
