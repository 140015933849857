//eslint-disable-next-line
export default {
  common: {
    soon: `Tez orada boshlanadi <div>katta jang!</div>`,
    available: 'Mavjud',
    play: "Click Start-ni O'ynang va qimmatbaho sovg'alarni yutib oling!",
    download: 'iOS va Android uchun Click Start dasturini yuklab oling!',
    rights: ' Barcha huquqlar himoyalangan',
    number: 'Raqam',
    amount: 'Miqdori',
    points: 'ball',
    details: "Tafsilotlarni ko'rish",
  },
  navigation: {
    mainFunc: 'Asosiy funktsiyalar',
    about: 'Clicklar haqida',
    game: "O'yinni bosing",
  },
  card: {
    title: 'Tezkor Start <span>Qulay Start</span>',
    walet: 'Shaxsiy <span> hamyon</span>',
    pass: "<span > Click Pass </span> har doim qo'lda",
    qr: '<span>QR kodlari</span> orqali tarjimalar',
    payment: "Qulay <span > joylarda to'lov</span>",
    earn: 'Pul ishlang <span> bosish </span>',
    play: "O'yinni o'ynang <span > ishga tushirish</span>",
  },
  articles: {
    start: 'Jamla <span>Sarfla</span> O’tkaz',
    startText:
      'To’lovlarni naqd pul yoki bank kartasisiz mustaqil amalga oshir. Click-hamyonda pul jamla. Virtual hamyonga pul o’tkazmalarini qabul qil yoki amalga oshir. Ilovani yuklab ol va hamyon ro’yxatdan o’tishing bilan ochiladi. ',
    pass: '<span>Click Pass</span> va <span>QR-kod</span> orqali to’lovlar. Doimo qo’l ostida',
    passText:
      'Endilikda sen Click Pass yoki QR-kod mavjud bo’lgan har qanday do’konda to’lovlarni amalga oshirishing mumkin. Endilikda do’konda qaytimni sanash yoki o’zing bilan naqd pul olib yurish umuman shart emas.',
    qr: '<span>QR-kodni</span> yubor Pul o’tkazmasini qabul qil',
    qrText:
      'Pulingni qayerga sarflashni mustaqil hal qil – do’stlaringga pul o’tkaz, yaqinlaringdan qabul qil – bularning barchasi uchun pul o’tkazuvchiga QR-kodni yuborish yoki ko’rsatishning o’zi yetarli.',
    payment: 'Cheksiz pul o’ztkazmalari',
    paymentText:
      'Bir necha “Click”da pul o’tkaz! Ota-onangdan pul so’rash uchun “So’rash” tugmasini bos va kerakli kontaktni tanla. Butun jarayon bir daqiqadan kamroq vaqt egallaydi. Yangi funksiya bilan sen hech qachon pulsiz qolmaysan.',
  },
  contest: {
    title: 'Bizning <span>tanlovimizda</span> ishtirok eting',
    subtitle: `Tanlovning barcha tafsilotlari va shartlarini "Start Start" sahifasida ko'rishingiz mumkin! Ishtirok etish uchun vaqt topish uchun hoziroq boring!`,
    linkJoin: "Qo'shilish",
    timerTitle: 'TANLOV OXIRIGACHA QOLGAN VAQT',
    timerTitleBefore: 'TANLOV BOSHLANGANCHA QOLGAN VAQT',
    days: 'Kunlar',
    hours: 'Soat',
    minuts: 'Daqiqa',
    seconds: 'Soniya',
    winnersTitle: "Bizning g'oliblarimiz",
    week: 'hafta',
    place: "o'rin",
    places: 'orinlar',
    seeUsers: "Barcha ishtirokchilarni ko'ring",
  },
  competition: {
    title:
      "O'yindagi vazifalarni hal qiling va <span>qimmatbaho sovg'alarni</span> yutib oling!",
    title2: '<span>Tanlov shartlari</span> oddiy va qiziqarli',
    title3: '<span>Eng yaxshi sovrinlar</span> sizni kutmoqda',
    title4: 'Ilovani iOS yoki Android - da <span>yuklab oling!</span>',
    place1: 'Noutbuk, markali futbolka, markali krujka, esdalik sertifikati',
    place2:
      "Smartfon, 100 000 so'mlik hamyonni to'ldirish, futbolka, krujka va esdalik sertifikati",
    place3:
      "Aqlli soat, 50 000 so'mlik hamyonni to'ldirish, futbolka, krujka va esdalik sertifikati",
    place4: 'Tovar belgisi futbolkasi, tovar belgisi krujkasi',
  },
  weeks: {
    week1: {
      title:
        "Ijtimoiy postlarni qayta joylashtirish orqali do'stlarni taklif qilish. tarmoqlar va messenjerlar",
      subtitle: "Do'stlaringizga to'g'ridan-to'g'ri yuboring!",
    },
    week2: {
      title:
        "Yo'naltiruvchi havola orqali do'stlarni taklif qilish, do'stlar havolani kuzatib, taklifni yuklab olishadi va ro'yxatdan o'tishadi",
      subtitle:
        "Do'stlaringizga havola havolasini nusxa ko'chiring va yuboring",
    },
    week3: {
      title:
        "Tranzaktsiyalar soni, kim ko'proq tranzaktsiyalarni amalga oshirsa, mukofot oladi",
      subtitle: "Ko'proq tranzaktsiyalar qiling boshqalarga qaraganda",
    },
    week4: {
      title:
        "E'lon qilingan summa bo'yicha bitimlar soni, masalan, 10, 20, 50, 100 ming so'mdan ortiq bitimlar soni.",
      subtitle:
        "100 ming so'm ko'proq operatsiyalarni amalga oshiring boshqalarga qaraganda",
    },
    week5: {
      title: "Hamyondan hamyonga olingan pul o'tkazmalari soni",
      subtitle: "Hamyoningizga eng ko'p pul o'tkazmalarini oling!",
    },
    week6: {
      title:
        "Hamyondan hamyonga olingan pul o'tkazmalari soni 10, 20 50, 100 ming so'mni tashkil etadi",
      subtitle:
        "Hamyondan hamyonga 10, 20 50, 100 ming so'm pul o'tkazmalarini oling",
    },
    week7: {
      title: "Hamyondan hamyonga yuborilgan pul o'tkazmalari soni",
      subtitle:
        "Hamyondan hamyonga pul o'tkazmalarini har kimdan ko'proq qiling",
    },
    week8: {
      title:
        "Hamyondan hamyonga yuborilgan pul o'tkazmalari soni 10, 20 50, 100 ming so'mni tashkil etadi",
      subtitle:
        "Hamyondan hamyonga pul o'tkazmalarini har kimdan ma'lum miqdorda amalga oshiring",
    },
  },
  list: {
    title: 'Tanlov ishtirokchilari',
  },
  share: 'Ulashish',
  invite: 'Taklif qiling',
  paymentCount: 'Bitimlar soni',
  paymentSum: "Belgilangan miqdor bo'yicha operatsiyalar soni",
  receiveCount: "Qabul qilingan o'tkazmalar soni",
  receiveSum: "E'lon qilingan miqdor bo'yicha olingan o'tkazmalar soni",
  remitCount: "Yuborilgan o'tkazmalar soni",
  remitSum: "E'lon qilingan miqdor bo'yicha yuborilgan o'tkazmalar soni",
  tender: {
    title: '<span>“Muvaffaqiyat cho’qqisi”</span> aksiyasi qoidalari',
    period: 'O’tkazish muddati: <span>01.12.2022 dan 26.01.2023 gacha</span>',
    deadline: 'Natijalarni aniqlash kuni: Aksiya davomida har payshanba kuni',
    organizer: 'Uyushtiruvchi: <span>“Click” MChJ</span>.',
    place:
      'Tanlov o’tkazilish joyi:  <span>Click Start</span> ilovasi / Ijtimoiy tarmoqlardagi  <span>Click Start</span> sahifalari.',
    gifts: {
      place1: {
        gift1: 'Noutbuk',
        gift2: 'Click Start logotipi bosilgan svitshot',
        gift3: 'Click Start logotipi bosilgan krujka',
        gift4: 'Stikerlar',
        gift5: 'Sertifikat',
      },
      place2: {
        gift1: 'Smartfon',
        gift2: 'Hamyonni 100 ming so’mga to’ldirish',
        gift3: 'Click Start logotipi bosilgan svitshot',
        gift4: 'Click Start logotipi bosilgan krujka',
        gift5: 'Stikerlar',
        gift6: 'Sertifikat',
      },
      place3: {
        gift1: 'Smart-soat',
        gift2: 'Hamyonni 50 so’mga to’ldirish',
        gift3: 'Click Start logotipi bosilgan svitshot',
        gift4: 'Click Start logotipi bosilgan krujka',
        gift5: 'Stikerlar',
        gift6: 'Sertifikat',
      },
      place4: {
        title: 'Boshqa sovg’alar 4-o’rindan 10-o’ringacha',
        gift1: 'Svitshotlar',
        gift2: 'Krujkalar',
        gift3: 'Stikerlar',
      },
    },
    mechanism: {
      title: 'Aksiyani o’tkazish mexanizmi',
      subtitle:
        'Aksiyaning umumiy davomiyligi 8 haftani tashkil etadi. Belgilangan vaqt davomida top-10 g’oliblarni aniqlash uchun, har hafta turli xil tanlovlar o’tkaziladi. Barcha shartlarni birinchi bo’lib bajargan ishtirokchilar g’olib deb topiladi. Natijalarni ushbu saytda bilish mumkin: <a href="https://click-start.uz/List">https://click-start.uz/List</a>, ishtirokchi ID-raqamini “Profil” – “Shaxsiy ma’lumotlar” (Mening identifikatsion raqamim (ID)) bo’limida ko’rish mumkin. Har safar haftalik tanlov sharti bajarilganda, foydalanuvchiga 1 ball taqdim etiladi. <br /> <br /> Belgilangan summadagi to’lovli tanlovlar bundan istisno (4,6,8 haftalar). Bu tanlovlarda ballar belgilangan to’lov summalariga muvofiq taqdim etiladi: 10 ming so’m – 10 ming ball, 20 ming so’m – 20 ming ball va hokazo.',
      programm: 'Tanlovlar programmasi',
      items: {
        item1: `<span>1-hafta:</span> Click Start ilovasiga ijtimoiy tarmoqlardan va messendjerlardan iloji boricha ko’proq do’stlarni “Bo’lishish” tugmasi yordamida taklif qilish. Boshlanishi 1-dekabr kuni soat 00.01 – tugallanishi 7-dekabr kuni soat 23.59.`,
        item2: `<span>2-hafta:</span> Click Start ilovasiga referal havola orqali iloji boricha ko’proq do’stlarni taklif qilish. Foydalanuvchining noyob referal havolasi ilovaning “Profil” – Referal tizim” bo’limida joylashgan. Boshlanishi 8-dekabr kuni soat 00.01 – tugallanishi 14-dekabr kuni soat 23.59.`,
        item3: `<span>3-hafta:</span> Eng ko’p to’lov amalga oshirish. To’lovlar har qanday summaga, har qanday mahsulot/xizmat uchun amalga oshirilishi mumkin. Boshlanishi 15-dekabr kuni soat 00.01 – tugallanishi 21-dekabr kuni soat 23.59.`,
        item4: `<span>4-hafta:</span> Belgilangan summada eng ko’p to’lov amalga oshirish. Belgilangan summalar – 10 000 so’m, 20 000 so’m, 50 000 so’m, 100 000 so’m. To’lovlar har qanday mahsulot/xizmat uchun amalga oshirilishi mumkin. Click Pass orqali amalga oshirilgan to'lovlar hisoblanmaydi.Boshlanishi 22-dekabr kuni soat 00.01 – tugallanishi 28-dekabr kuni soat 23.59.`,
        item5: `<span>5-hafta:</span> Hafta davomida eng ko’p hamyondan-hamyonga o’tkazma qabul qilish. 
        Har qanday miqdordagi pul o'tkazmasi Click Start foydalanuvchisidan Click Start foydalanuvchisiga amalga oshirilgan bo'lishi lozim. Boshlanishi 29-dekabr kuni soat 00.01 – tugallanishi 4-yanvar kuni soat 23.59.
        `,
        item6: `<span>6-hafta:</span> Hamyondan-hamyonga belgilangan summada eng ko’p miqdorda o’tkazma qabul qilish. Belgilangan summalar – 10 000 so’m, 20 000 so’m, 50 000 so’m, 100 000 so’m. Belgilangan summadagi pul o'tkazmasi Click Start ilovasida Click Start foydalanuvchisidan qabul qilinishi lozim. Boshlanishi 5-yanvar kuni soat 00.01 – tugallanishi 11-yanvar kuni soat 23.59.`,
        item7: `<span>7-hafta:</span> Hamyondan-hamyonga eng ko’p pul o’tkazmalarini amalga oshirish.
        Har qanday miqdordagi pul o'tkazmasi Click Start foydalanuvchisidan Click Start foydalanuvchisiga amalga oshirilgan bo'lishi lozim. Boshlanishi 12-yanvar kuni soat 00.01 – tugallanishi 18-yanvar kuni soat 23.59.
        `,
        item8: `<span>8-hafta:</span> Hamyondan hamyonga belgilangan summada eng ko’p o’tkazma amalga oshirish. Belgilangan summalar – 10 000 so’m, 20 000 so’m, 50 000 so’m, 100 000 so’m. Belgilangan summadagi pul o'tkazmasi Click Start ilovasida Click Start foydalanuvchisidan qabul qilinishi lozim.  Boshlanishi 12-yanvar kuni soat 00.01 – tugallanishi 18-yanvar kuni soat 23.59.`,
      },
    },
    competitors: {
      title: 'Ishtirokchilar',
      subtitle:
        'Aksiya ishtirokchilari deb O’zbekiston Respublikasi fuqarolari (Click Start ilovasi foydalanuvchilari) bo’lgan jismoniy shaxslar hisoblanadi.',
    },
    expired:
      'Diqqat! Agar sovrindor sovrinni qabul qilishdan bosh tortgan yoki Aksiya natijalari e’lon qilingan paytdan boshlab 10 bank kuni ichida uni qabul qilish xohishini bildirmagan bo’lsa, sovrin talab qilinmagan, sovrindor esa sovrinni qabul qilish huquqini yo’qotgan deb hisoblanadi. Belgilangan muddatda sovrinni talab qilmagan yoki qabul qilish xohishini bildirmagan Tanlov g’olibi muddat o’tgandan so’ng Tashkilotchidan sovrinni talab qilish huquqiga ega emas.',
  },
}
