//eslint-disable-next-line
export default {
  common: {
    soon: `Тез орада бошланади <div>катта жанг!</div>`,
    available: 'Мавжуд',
    play: 'Click Start-ни Ўйнанг ва қимматбаҳо совғаларни ютиб олинг!',
    download: 'iOS ва Android учун Click Start дастурини юклаб олинг!',
    rights: ' Барча ҳуқуқлар ҳимояланган',
    number: 'Рақам',
    amount: 'Миқдори',
    points: 'балл',
    details: 'Тафсилотларни кўриш',
  },
  navigation: {
    mainFunc: 'Асосий функциялар',
    about: 'Кликлар ҳақида',
    game: 'Ўйинни босинг',
  },
  card: {
    title: 'Тезкор Start <span>Қулай Start</span>',
    walet: 'Шахсий <span> ҳамён</span>',
    pass: '<span > Click Pass </span> ҳар доим қўлда',
    qr: '<span>QR кодлари</span> орқали таржималар',
    payment: 'Қулай <span > жойларда тўлов</span>',
    earn: 'Пул ишланг <span> босиш </span>',
    play: 'Ўйинни ўйнанг <span > ишга тушириш</span>',
  },
  articles: {
    start: 'Жамла <span>Сарфла</span> Ўтказ',
    startText:
      'Тўловларни нақд пул ёки банк картасисиз мустақил амалга ошир. Click-ҳамёнда пул жамла. Virtual ҳамёнга пул ўтказмаларини қабул қил ёки амалга ошир. Иловани юклаб ол ва ҳамён рўйхатдан ўтишинг билан очилади. ',
    pass: '<span>Click Pass</span> ва <span>QR-код</span> орқали тўловлар. Доимо қўл остида',
    passText:
      'Ендиликда сен Click Pass ёки QR-код мавжуд бўлган ҳар қандай дўконда тўловларни амалга оширишинг мумкин. Ендиликда дўконда қайтимни санаш ёки ўзинг билан нақд пул олиб юриш умуман шарт емас.',
    qr: '<span>QR-кодни</span> юбор Пул ўтказмасини қабул қил',
    qrText:
      'Пулингни қаерга сарфлашни мустақил ҳал қил – дўстларингга пул ўтказ, яқинларингдан қабул қил – буларнинг барчаси учун пул ўтказувчига QR-кодни юбориш ёки кўрсатишнинг ўзи етарли.',
    payment: 'Чексиз пул ўзтказмалари',
    paymentText:
      'Бир неча "Click"да пул ўтказ! Ота-онангдан пул сўраш учун "Сўраш" тугмасини бос ва керакли контактни танла. Бутун жараён бир дақиқадан камроқ вақт егаллайди. Янги функсия билан сен ҳеч қачон пулсиз қолмайсан.',
  },
  contest: {
    title: 'Бизнинг <span>танловимизда</span> иштирок етинг',
    subtitle: `Танловнинг барча тафсилотлари ва шартларини "Start Start" саҳифасида кўришингиз мумкин! Иштирок етиш учун вақт топиш учун ҳозироқ боринг!`,
    linkJoin: 'Қўшилиш',
    timerTitle: 'ТАНЛОВ ОХИРИГАЧА ҚОЛГАН ВАҚТ',
    timerTitleBefore: 'ТАНЛОВ БОШЛАНГАНЧА ҚОЛГАН ВАҚТ',
    days: 'Кунлар',
    hours: 'Соат',
    minuts: 'Дақиқа',
    seconds: 'Сония',
    winnersTitle: 'Бизнинг ғолибларимиз',
    week: 'ҳафта',
    place: 'орин',
    places: 'оринлар',
    seeUsers: 'Барча иштирокчиларни кўринг',
  },
  competition: {
    title:
      'Ўйиндаги вазифаларни ҳал қилинг ва <span>қимматбаҳо совғаларни</span> ютиб олинг!',
    title2: '<span>Танлов шартлари</span> оддий ва қизиқарли',
    title3: '<span>Енг яхши совринлар</span> сизни кутмоқда',
    title4: 'Иловани iOS ёки Android - да <span>юклаб олинг!</span>',
    place1: 'Ноутбук, маркали футболка, маркали кружка, есдалик сертификати',
    place2:
      'Смартфон, 100 000 сўмлик ҳамённи тўлдириш, футболка, кружка ва есдалик сертификати',
    place3:
      'Ақлли соат, 50 000 сўмлик ҳамённи тўлдириш, футболка, кружка ва есдалик сертификати',
    place4: 'Товар белгиси футболкаси, товар белгиси кружкаси',
  },
  weeks: {
    week1: {
      title:
        'Ижтимоий постларни қайта жойлаштириш орқали дўстларни таклиф қилиш. тармоқлар ва мессенжерлар',
      subtitle: 'Дўстларингизга тўғридан-тўғри юборинг!',
    },
    week2: {
      title:
        'Йўналтирувчи ҳавола орқали дўстларни таклиф қилиш, дўстлар ҳаволани кузатиб, таклифни юклаб олишади ва рўйхатдан ўтишади',
      subtitle: 'Дўстларингизга ҳавола ҳаволасини нусха кўчиринг ва юборинг',
    },
    week3: {
      title:
        'Транзакциялар сони, ким кўпроқ транзакцияларни амалга оширса, мукофот олади',
      subtitle: 'Кўпроқ транзакциялар қилинг бошқаларга қараганда',
    },
    week4: {
      title:
        'Еълон қилинган summa бўйича битимлар сони, масалан, 10, 20, 50, 100 минг сўмдан ортиқ битимлар сони.',
      subtitle:
        '100 минг сўм кўпроқ операцияларни амалга оширинг бошқаларга қараганда',
    },
    week5: {
      title: 'Ҳамёндан ҳамёнга олинган пул ўтказмалари сони',
      subtitle: 'Ҳамёнингизга енг кўп пул ўтказмаларини олинг!',
    },
    week6: {
      title:
        'Ҳамёндан ҳамёнга олинган пул ўтказмалари сони 10, 20 50, 100 минг сўмни ташкил етади',
      subtitle:
        'Ҳамёндан ҳамёнга 10, 20 50, 100 минг сўм пул ўтказмаларини олинг',
    },
    week7: {
      title: 'Ҳамёндан ҳамёнга юборилган пул ўтказмалари сони',
      subtitle: 'Ҳамёндан ҳамёнга пул ўтказмаларини ҳар кимдан кўпроқ қилинг',
    },
    week8: {
      title:
        'Ҳамёндан ҳамёнга юборилган пул ўтказмалари сони 10, 20 50, 100 минг сўмни ташкил етади',
      subtitle:
        'Ҳамёндан ҳамёнга пул ўтказмаларини ҳар кимдан маълум миқдорда амалга оширинг',
    },
  },
  list: {
    title: 'Танлов иштирокчилари',
  },
  share: 'Улашиш',
  invite: 'Таклиф',
  paymentCount: 'Битимлар сони',
  paymentSum: 'Еълон миқдори учун битимлар сони',
  receiveCount: 'Қабул қилинган ўтказмалар сони',
  receiveSum: 'Еълон миқдори учун қабул ўтказмалари сони',
  remitCount: 'Юборилган ўтказмалар сони',
  remitSum: 'Еълон қилинган суммага мувофиқ юборилган ўтказмалар сони',
  tender: {
    title: '<span>"Муваффақият чўққиси"</span> аксияси қоидалари',
    period: 'Ўтказиш муддати: <span>01.12.2022 дан 26.01.2023 гача</span>',
    deadline:
      'Натижаларни аниқлаш куни: Аксия ўтказиш даврида ҳар пайшанба куни',
    organizer: 'Уюштирувчи: <span>“Click” Мчж</span>.',
    place:
      'Танлов ўтказилиш жойи:  <span>Click Start</span> иловаси / Ижтимоий тармоқлардаги <span>Click Start</span> саҳифалари.',
    gifts: {
      place1: {
        gift1: 'Ноутбук',
        gift2: 'Click Start логотипи босилган свитшот',
        gift3: 'Click Start логотипи босилган кружка',
        gift4: 'Стикерлар',
        gift5: 'Cетификат',
      },
      place2: {
        gift1: 'Смартфон',
        gift2: 'Ҳамённи 100 минг сўмга тўлдириш',
        gift3: 'Click Start логотипи босилган свитшот',
        gift4: 'Click Start логотипи босилган кружка',
        gift5: 'Стикерлар',
        gift6: 'Cетификат',
      },
      place3: {
        gift1: 'Smart-соат',
        gift2: 'Ҳамённи 50 минг сўмга тўлдириш',
        gift3: 'Click Start логотипи босилган свитшот',
        gift4: 'Click Start логотипи босилган кружка',
        gift5: 'Стикерлар',
        gift6: 'Cетификат',
      },
      place4: {
        title: 'Бошқа совғалар 4-ўриндан 10-ўрингача',
        gift1: 'Свитшотлар',
        gift2: 'Кружкалар',
        gift3: 'Стикерлар',
      },
    },
    mechanism: {
      title: 'Аксияни ўтказиш механизми',
      subtitle:
        'Аксиянинг умумий давомийлиги 8 ҳафтани ташкил етади. Белгиланган вақт давомида топ-10 ғолибларни аниқлаш учун, ҳар ҳафта турли хил танловлар ўтказилади. Барча шартларни биринчи бўлиб бажарган иштирокчилар ғолиб деб топилади. Натижаларни ушбу сайтда билиш мумкин: <a href="https://click-start.uz/List">https://click-start.uz/List</a>, иштирокчи ID-рақамини "Profil" – "Шахсий маълумотлар" (Менинг идентификацион рақамим (ID)) бўлимида кўриш мумкин. Ҳар сафар ҳафталик танлов шарти бажарилганда, фойдаланувчига 1 балл тақдим етилади. <br /> <br /> Белгиланган суммадаги тўловли танловлар бундан истисно (4,6,8 ҳафталар). Бу танловларда баллар белгиланган тўлов суммаларига мувофиқ тақдим етилади: 10 минг сўм – 10 минг балл, 20 минг сўм – 20 минг балл ва ҳоказо.',
      programm: 'Танловлар программаси',
      items: {
        item1: `<span>1-ҳафта:</span> Click Start иловасига ижтимоий тармоқлардан ва мессенджерлардан иложи борича кўпроқ дўстларни "Бўлишиш" тугмаси ёрдамида таклиф қилиш. Бошланиши 1-декабр куни соат 00.01 – тугалланиши 7-декабр куни соат 23.59.`,
        item2: `<span>2-ҳафта:</span> Click Start иловасига реферал ҳавола орқали иложи борича кўпроқ дўстларни таклиф қилиш. Фойдаланувчининг ноёб реферал ҳаволаси илованинг "Profil" – Реферал тизим" бўлимида жойлашган. Бошланиши 8-декабр куни соат 00.01 – тугалланиши 14-декабр куни соат 23.59.`,
        item3: `<span>3-ҳафта:</span> Енг кўп тўлов амалга ошириш. Тўловлар ҳар қандай суммага, ҳар қандай маҳсулот/хизмат учун амалга оширилиши мумкин. Бошланиши 15-декабр куни соат 00.01 – тугалланиши 21-декабр куни соат 23.59.`,
        item4: `<span>4-ҳафта:</span> Белгиланган суммада енг кўп тўлов амалга ошириш. Белгиланган суммалар – 10 000 сўм, 20 000 сўм, 50 000 сўм, 100 000 сўм. Тўловлар ҳар қандай маҳсулот/хизмат учун амалга оширилиши мумкин. Click Пасс орқали амалга оширилган тўловлар ҳисобланмайди. Бошланиши 22-декабр куни соат 00.01 – тугалланиши 28-декабр куни соат 23.59.`,
        item5: `<span>5-ҳафта:</span> Ҳафта давомида енг кўп ҳамёндан-ҳамёнга ўтказма қабул қилиш. 
        Ҳар қандай миқдордаги пул ўтказмаси Click Start фойдаланувчисидан Click Start фойдаланувчисига амалга оширилган бўлиши лозим. Бошланиши 29-декабр куни соат 00.01 – тугалланиши 4-январ куни соат 23.59.`,
        item6: `<span>6-ҳафта:</span> Ҳамёндан-ҳамёнга белгиланган суммада енг кўп миқдорда ўтказма қабул қилиш. Белгиланган суммалар – 10 000 сўм, 20 000 сўм, 50 000 сўм, 100 000 сўм. Белгиланган суммадаги пул ўтказмаси Click Start иловасида Click Start фойдаланувчисидан қабул қилиниши лозим. Бошланиши 5-январ куни соат 00.01 – тугалланиши 11-январ куни соат 23.59.`,
        item7: `<span>7-ҳафта:</span> Ҳамёндан-ҳамёнга енг кўп пул ўтказмаларини амалга ошириш.
        Ҳар қандай миқдордаги пул ўтказмаси Click Start фойдаланувчисидан Click Start фойдаланувчисига амалга оширилган бўлиши лозим. Бошланиши 12-январ куни соат 00.01 – тугалланиши 18-январ куни соат 23.59.`,
        item8: `<span>8-ҳафта:</span> Ҳамёндан ҳамёнга белгиланган суммада енг кўп ўтказма амалга ошириш. Белгиланган суммалар – 10 000 сўм, 20 000 сўм, 50 000 сўм, 100 000 сўм. Белгиланган суммадаги пул ўтказмаси Click Start иловасида Click Start фойдаланувчисидан қабул қилиниши лозим. Бошланиши 12-январ куни соат 00.01 – тугалланиши 18-январ куни соат 23.59.`,
      },
    },
    competitors: {
      title: 'Иштирокчилар',
      subtitle:
        'Аксия иштирокчилари деб Ўзбекистон Республикаси фуқаролари (Click Start иловаси фойдаланувчилари) бўлган жисмоний шахслар ҳисобланади.',
    },
    expired:
      'Диққат! Агар совриндор совринни қабул қилишдан бош тортган ёки Аксия натижалари еълон қилинган пайтдан бошлаб 10 банк куни ичида уни қабул қилиш хоҳишини билдирмаган бўлса, соврин талаб қилинмаган, совриндор еса совринни қабул қилиш ҳуқуқини йўқотган деб ҳисобланади. Белгиланган муддатда совринни талаб қилмаган ёки қабул қилиш хоҳишини билдирмаган Танлов ғолиби муддат ўтгандан сўнг Ташкилотчидан совринни талаб қилиш ҳуқуқига ега емас.',
  },
}
