import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from 'src/components/Header'
import apple from '../../resources/images/apple.svg'
import google from '../../resources/images/google.svg'
import girl from '../../resources/images/competition/girl.png'
import Tabs from 'src/components/Tabs'
import axios from 'axios'
import { baseURL } from 'src/App'
import dayjs from 'dayjs'

const List = () => {
  const { t, i18n } = useTranslation()
  const [weekActive, setweekActive] = useState(-1)
  const lang = true
  const [users, setUsers] = useState<any>([])
  const [dynamicURL, setDynamicURL] = useState<string>('')
  const [deadlineType, setDeadlineType] = useState<string | null>(null)
  const [activeDates, setactiveDates] = useState<any>(null)
  const [languageDetect, setlanguageDetect] = useState<string>('')
  const [activatedWeeks, setActivatedWeeks] = useState([])

  useEffect(() => {
    if (deadlineType) {
      setDynamicURL(deadlineType)
    }
  }, [deadlineType])

  useEffect(() => {
    if (deadlineType !== null) setDeadlineType(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekActive])

  useEffect(() => {
    if (i18n.language) {
      setlanguageDetect(i18n.language)
    }
  }, [i18n.language])

  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    if (dynamicURL) {
      setisLoading(true)
      axios.get(`${baseURL}${dynamicURL}`).then((response) => {
        setUsers(response.data.data)
        setisLoading(false)
      })
    }
  }, [dynamicURL, deadlineType])

  useEffect(() => {
    if (activeDates === null) {
      axios
        .get(`https://api.click.uz/start/challenge/api/leaderboard/schedules`)
        .then((response) => {
          setactiveDates(response.data.data)
        })
    }
  }, [activeDates])

  useEffect(() => {
    if (activeDates) {
      let closestType: string | null = null
      const nowDate = dayjs(new Date())
      const activetedWeeksArray: any = []

      if (nowDate.isBefore(dayjs(activeDates[0].start))) {
        closestType = activeDates[0].route
        setweekActive(1)
      } else {
        activeDates?.forEach((n: any) => {
          if (
            nowDate.isAfter(dayjs(n.start)) &&
            nowDate.isBefore(dayjs(n.end))
          ) {
            closestType = n.route
          }
          if (nowDate.isAfter(dayjs(n.start))) {
            activetedWeeksArray.push(n.route)
          }
        })
      }
      setDeadlineType(closestType)
      setActivatedWeeks(activetedWeeksArray)
    }
  }, [activeDates])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="list">
      <Header lang={lang} />
      <div className="list__title" style={{ padding: '0 20px' }}>
        {t('list.title')}
      </div>
      <Tabs
        weekActive={weekActive}
        setweekActive={setweekActive}
        setDynamicURL={setDynamicURL}
        deadlineType={deadlineType}
        activatedWeeks={activatedWeeks}
      />
      {!isLoading ? (
        <div className="main__winners-places-bot" style={{ padding: '0 20px' }}>
          <table className="main__winners-list">
            <thead>
              <tr>
                <td>№</td>
                <td>{`${t('common.number')} ID`}</td>
                <td>{`${t('common.amount')}`}</td>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((n: any, index: any) => (
                  <tr key={n.user_id}>
                    <td>{index + 1}</td>
                    <td>{`ID ${n.user_id}`}</td>
                    <td>{n.count}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          <div className="loader"></div>
        </div>
      )}
      <div className="competition__top competition__footer">
        <div className="competition__top-inner center-block">
          <div
            className="competition__top-inner-title"
            dangerouslySetInnerHTML={{
              __html: t('competition.title4'),
            }}
          ></div>
          <div
            className="main__top-links"
            style={{ justifyContent: 'flex-start' }}
          >
            <a
              href="https://apps.apple.com/uz/app/click-start/id1590997023"
              className="main__top-link"
              target="blank"
            >
              <div className="main__top-link-img">
                <img src={apple} alt="" />
              </div>
              <div className="main__top-link-text">
                <span>{t('common.available')}</span>
                <span>App Store</span>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.click.clickstart&hl=en&gl=US"
              className="main__top-link"
              target="blank"
            >
              <div className="main__top-link-img">
                <img src={google} alt="" />
              </div>
              <div className="main__top-link-text">
                <span>{t('common.available')}</span>
                <span>Google Play</span>
              </div>
            </a>
          </div>
          <div className="competition__top-inner-img girl">
            <img src={girl} alt="" />
          </div>
          <div
            className={`competition__copyright ${
              languageDetect === 'uzk' ? 'change__font__medium' : ''
            }`}
          >
            Copyright &copy; {new Date().getFullYear()} Click Start Contest.
            {t('common.rights')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default List
