import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const Lang = ({ lang }: any) => {
  const { i18n } = useTranslation()
  const history = useHistory()

  const changeLanguage = (language: any) => {
    i18n.changeLanguage(language)
  }

  useEffect(() => {
    if (history.location.pathname === '/Tender' && i18n.language === 'en') {
      i18n.changeLanguage('ru')
    }
  }, [history.location.pathname, i18n])

  return (
    <div className="header__lang" style={lang ? { top: '40px' } : {}}>
      <div
        className={`header__lang-item ${
          i18n.language === 'ru' ? 'active' : ''
        }`}
        onClick={() => {
          changeLanguage('ru')
        }}
      >
        <span className="header__lang-text">РУ</span>
      </div>
      <div
        className={`header__lang-item ${
          i18n.language === 'uzk' ? 'active' : ''
        }`}
        onClick={() => {
          changeLanguage('uzk')
        }}
      >
        <span className="header__lang-text">UZ</span>
      </div>
      <div
        className={`header__lang-item ${
          i18n.language === 'uz' ? 'active' : ''
        }`}
        onClick={() => {
          changeLanguage('uz')
        }}
      >
        <span className="header__lang-text">O'Z</span>
      </div>
      {history.location.pathname === '/Tender' ? (
        ''
      ) : (
        <div
          className={`header__lang-item ${
            i18n.language === 'en' ? 'active' : ''
          }`}
          onClick={() => {
            changeLanguage('en')
          }}
        >
          <span className="header__lang-text">EN</span>
        </div>
      )}
    </div>
  )
}

export default Lang
