import React, { useEffect, useState } from 'react'
import logo from '../resources/images/logo.svg'
import apple from '../resources/images/apple.svg'
import google from '../resources/images/google.svg'
import stars from '../resources/images/stars.svg'
import simpleParallax from 'simple-parallax-js'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t, i18n } = useTranslation()
  const [languageDetect, setlanguageDetect] = useState<string>('')

  useEffect(() => {
    if (i18n.language) {
      setlanguageDetect(i18n.language)
    }
  }, [i18n.language])

  useEffect(() => {
    // parallax effect
    let images = document.querySelectorAll('.js-parallax')
    new simpleParallax(images as any, {
      orientation: 'left',
      delay: 0.5,
      overflow: true,
      transition: 'cubic-bezier(0,0,0,1)',
    })
  }, [])

  return (
    <div className="footer">
      <div className="footer__inner center-block">
        <div className="header__logo" style={{ justifyContent: 'center' }}>
          <div className="header__logo-img">
            <img src={logo} alt="" />
          </div>
          <div className="header__logo-name">
            <span>click</span>
            <span>start</span>
          </div>
        </div>
        <div className="footer__title">{t('common.download')}</div>
        <div className="main__top-links">
          <a
            href="https://apps.apple.com/uz/app/click-start/id1590997023"
            className="main__top-link"
            target="blank"
          >
            <div className="main__top-link-img">
              <img src={apple} alt="" />
            </div>
            <div className="main__top-link-text">
              <span>{t('common.available')}</span>
              <span>App Store</span>
            </div>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.click.clickstart&hl=en&gl=US"
            className="main__top-link"
            target="blank"
          >
            <div className="main__top-link-img">
              <img src={google} alt="" />
            </div>
            <div className="main__top-link-text">
              <span>{t('common.available')}</span>
              <span>Google Play</span>
            </div>
          </a>
        </div>
        <div
          className={`footer__copyright ${
            languageDetect === 'uzk' ? 'change__font__medium' : ''
          }`}
        >
          Copyright &copy; {new Date().getFullYear()} Click Start.
          {t('common.rights')}
        </div>
        <img src={stars} alt="" className="stars1 js-parallax" />
        <img src={stars} alt="" className="stars2 js-parallax" />
      </div>
    </div>
  )
}

export default Footer
