import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type IProps = {
  activeDate: string | null
}

const Time = ({ activeDate }: IProps) => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const { t, i18n } = useTranslation()

  const [languageDetect, setlanguageDetect] = useState<string>('')

  useEffect(() => {
    if (i18n.language) {
      setlanguageDetect(i18n.language)
    }
  }, [i18n.language])

  useEffect(() => {
    if (activeDate) {
      let deadline = activeDate

      const getTime = (props?: any) => {
        const time = Date.parse(
          dayjs(Date.parse(deadline) - Date.now())
            .subtract(5, 'hour')
            .format('YYYY-MM-DDTHH:mm:ss'),
        )

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)))
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24))
        setMinutes(Math.floor((time / 1000 / 60) % 60))
        setSeconds(Math.floor((time / 1000) % 60))
      }

      const interval = setInterval(() => getTime(deadline))

      return () => clearInterval(interval)
    }
  }, [activeDate])

  return (
    <>
      {activeDate ? (
        <div className="timer" role="timer">
          <div className="box">
            <p id="day">
              {/* {days < 10 ? '0' + days : days} */}
              {days < 0 ? '00' : days < 10 ? '0' + days : days}
            </p>
            <span className="text">{t('contest.days')}</span>
          </div>
          <div className="box">
            <p id="hour">
              {/* {hours < 10 ? '0' + hours : hours} */}
              {hours < 0 ? '00' : hours < 10 ? '0' + hours : hours}
            </p>
            <span className="text">{t('contest.hours')}</span>
          </div>
          <div className="box">
            <p id="minute">
              {/* {minutes < 10 ? '0' + minutes : minutes} */}
              {minutes < 0 ? '00' : minutes < 10 ? '0' + minutes : minutes}
            </p>
            <span
              className={`text ${
                languageDetect === 'uzk' ? 'change__font__medium' : ''
              }`}
            >
              {t('contest.minuts')}
            </span>
          </div>
          <div className="box">
            <p id="second">
              {/* {seconds < 10 ? '0' + seconds : seconds} */}
              {seconds < 0 ? '00' : seconds < 10 ? '0' + seconds : seconds}
            </p>
            <span className="text">{t('contest.seconds')}</span>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          <div className="loader"></div>
        </div>
      )}
    </>
  )
}

export default Time
