import i18n from "i18next";
import transEn from "./locales/en";
import transRu from "./locales/ru";
import transUzk from "./locales/uzk";
import transUz from "./locales/uz";
import { initReactI18next } from "react-i18next";

const currentLang = localStorage.getItem("currentLang");

i18n.on("languageChanged", (lng) => {
  localStorage.setItem("currentLang", lng);
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: transEn,
      },
      ru: {
        translation: transRu,
      },
      uzk: {
        translation: transUzk,
      },
      uz: {
        translation: transUz,
      },
    },
    lng: currentLang || "ru",
    fallbackLng: "ru",
    detection: {
      order: ["queryString", "cookie", "localStorage"],
      cache: ["cookie"],
    },

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
