import { BrowserRouter, Route } from 'react-router-dom'
import Main from './pages/Main/Main'
import Сompetition from './pages/Сompetition/Сompetition'
import './i18n'
import List from './pages/List/List'
import Tender from './pages/Tender/Tender'

export const baseURL = `https://api.click.uz/start/challenge/api/leaderboard`

// const users = [
//   {
//     user_id: 108829108,
//     count: 2549,
//   },
//   {
//     user_id: 108829108,
//     count: 2549,
//   },
//   {
//     user_id: 108829108,
//     count: 2549,
//   },
// ]

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route exact path="/" component={() => <Main />} />
        <Route path="/Competition" component={() => <Сompetition />} />
        <Route path="/List" component={() => <List />} />
        <Route path="/Tender" component={() => <Tender />} />
      </BrowserRouter>
    </div>
  )
}

export default App
