import React, { useEffect, useMemo, useState } from 'react'

// Images
import Header from '../../components/Header'
// import apple from '../../resources/images/apple.svg'
import apple2 from '../../resources/images/apple2.svg'
import google from '../../resources/images/google.svg'
import guys from '../../resources/images/guys.png'
import card1 from '../../resources/images/card1.png'
import card2 from '../../resources/images/card2.png'
import card3 from '../../resources/images/card3.png'
import card4 from '../../resources/images/card4.png'
import card5 from '../../resources/images/card5.png'
import card6 from '../../resources/images/card6.png'

import phone1 from '../../resources/images/phone1.png'
import phone1en from '../../resources/images/phone1en.png'
import phone1uz from '../../resources/images/phone1uz.png'
import phone1oz from '../../resources/images/phone1oz.png'
import phone2 from '../../resources/images/phone2.png'
import phone2en from '../../resources/images/phone2en.png'
import phone2uz from '../../resources/images/phone2uz.png'
import phone2oz from '../../resources/images/phone2oz.png'
import phone3 from '../../resources/images/phone3.png'
import phone3en from '../../resources/images/phone3en.png'
import phone3oz from '../../resources/images/phone3oz.png'
import phone3uz from '../../resources/images/phone3uz.png'
import phone4 from '../../resources/images/phone4.png'
import phone4en from '../../resources/images/phone4en.png'
import phone4uz from '../../resources/images/phone4uz.png'
import phone4oz from '../../resources/images/phone4oz.png'

import gift from '../../resources/images/gift.png'
import Footer from '../../components/Footer'
import stars from '../../resources/images/stars.svg'
import cloud1 from '../../resources/images/cloud1.svg'
import cloudTop from '../../resources/images/cloudTop.svg'
import cloud2 from '../../resources/images/cloud2.svg'
import rock from '../../resources/images/rock.svg'
import simpleParallax from 'simple-parallax-js'
import 'aos/dist/aos.css'
import Aos from 'aos'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Particles from 'react-particles'
import type { Engine } from 'tsparticles-engine'
import { loadStarsPreset } from 'tsparticles-preset-stars'

import steam from '../../resources/images/topBg/Steam.png'
import moon from '../../resources/images/topBg/Moon.png'
import mountains from '../../resources/images/topBg/Mountains.png'
import stouns from '../../resources/images/topBg/Stouns.png'
import bg from '../../resources/images/topBg/Back.svg'
import imgbg from '../../resources/images/topBg/Img.png'

import winner from '../../resources/images/winner.svg'
import place1 from '../../resources/images/winner/1.svg'
import place2 from '../../resources/images/winner/2.svg'
import place3 from '../../resources/images/winner/3.svg'
import Time from 'src/components/Time'
import Tabs from 'src/components/Tabs'
import axios from 'axios'
import { baseURL } from 'src/App'
import dayjs from 'dayjs'

const Main = () => {
  const { t, i18n } = useTranslation()
  const [weekActive, setweekActive] = useState(-1)
  const [users, setUsers] = useState<any>([])
  const [dynamicURL, setDynamicURL] = useState<string>('')
  const [deadlineDate, setDeadlineDate] = useState<string | null>(null)
  const [deadlineType, setDeadlineType] = useState<string | null>(null)
  const [activeDates, setactiveDates] = useState<any>(null)
  const [activatedWeeks, setActivatedWeeks] = useState([])

  useEffect(() => {
    if (deadlineType) {
      setDynamicURL(deadlineType)
    }
  }, [deadlineType])

  useEffect(() => {
    if (deadlineType !== null) setDeadlineType(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekActive])

  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    if (dynamicURL) {
      setisLoading(true)
      axios.get(`${baseURL}${dynamicURL}`).then((response) => {
        setUsers(response.data.data)
        setisLoading(false)
      })
    }
    // if (deadlineType !== null) setDeadlineType(null)
  }, [dynamicURL, deadlineType])

  useEffect(() => {
    if (activeDates === null) {
      axios
        .get(`https://api.click.uz/start/challenge/api/leaderboard/schedules`)
        .then((response) => {
          setactiveDates(response.data.data)
        })
    }
  }, [activeDates])

  useEffect(() => {
    if (activeDates) {
      let closestDate: string | null = null
      let closestType: string | null = null
      const nowDate = dayjs(new Date())

      const activetedWeeksArray: any = []
      if (
        nowDate.isBefore(
          dayjs(activeDates[0].start)
            .subtract(5, 'hour')
            .format('YYYY-MM-DDTHH:mm:ss'),
        )
      ) {
        closestDate = activeDates[0].start
        closestType = activeDates[0].route
        setweekActive(1)
      } else {
        activeDates?.forEach((n: any) => {
          if (
            nowDate.isAfter(
              dayjs(n.start).subtract(5, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
            ) &&
            nowDate.isBefore(
              dayjs(n.end).subtract(5, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
            )
          ) {
            closestDate = n.end
            closestType = n.route
          }
          if (
            nowDate.isAfter(
              dayjs(n.start).subtract(5, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
            )
          ) {
            activetedWeeksArray.push(n.route)
          }
        })
      }
      setDeadlineDate(closestDate)
      setDeadlineType(closestType)
      setActivatedWeeks(activetedWeeksArray)
    }
  }, [activeDates])

  useEffect(() => {
    // aos animation
    Aos.init()

    // parallax effect
    let images = document.querySelectorAll('.js-parallax')
    new simpleParallax(images as any, {
      orientation: 'left',
      delay: 0.5,
      overflow: true,
      transition: 'cubic-bezier(0,0,0,1)',
    })

    let imagesCloud = document.querySelectorAll('.js-parallaxCloud')
    new simpleParallax(imagesCloud as any, {
      orientation: 'right',
      delay: 0.5,
      overflow: true,
      transition: 'cubic-bezier(0,0,0,1)',
    })
  }, [])

  const customInit = useMemo(
    () =>
      async (engine: Engine): Promise<void> => {
        await loadStarsPreset(engine)
      },
    [],
  )

  const options = {
    preset: 'stars',
    particles: {
      number: {
        density: {
          enable: true,
          area: 1080,
        },
        limit: 0,
        value: 50,
      },
    },
  }

  const [languageDetect, setlanguageDetect] = useState<string>('')

  useEffect(() => {
    if (i18n.language) {
      setlanguageDetect(i18n.language)
    }
  }, [i18n.language])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="main">
      <div className="main__top ">
        <Header />
        {/* <div className="main__top-inner center-block">
          <div
            className={`main__top-title `}
            dangerouslySetInnerHTML={{
              __html: t('common.soon'),
            }}
          ></div>
          <div className="main__top-links">
            <a
              href="https://apps.apple.com/uz/app/click-start/id1590997023"
              className="main__top-link"
              target="blank"
            >
              <div className="main__top-link-img">
                <img src={apple} alt="" />
              </div>
              <div className="main__top-link-text">
                <span>{t('common.available')}</span>
                <span>App Store</span>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.click.clickstart&hl=en&gl=US"
              className="main__top-link"
              target="blank"
            >
              <div className="main__top-link-img">
                <img src={google} alt="" />
              </div>
              <div className="main__top-link-text">
                <span>{t('common.available')}</span>
                <span>Google Play</span>
              </div>
            </a>
          </div>
          <div className="main__top-guys">
            <img
              src={guys}
              alt=""
              data-aos="zoom-in"
              data-aos-offset="300"
              // data-aos-duration="500"
            />
          </div>
        </div> */}
        <div className="main__competition center-block">
          <div className="main__competition-left">
            <div
              className="main__title"
              dangerouslySetInnerHTML={{
                __html: t('contest.title'),
              }}
            ></div>
            <div
              className={`main__subtitle ${
                languageDetect === 'uzk' ? 'change__font__medium' : ''
              }`}
            >
              {t('contest.subtitle')}
            </div>
            <div className="main__links">
              <Link
                to={`/Competition`}
                className={
                  languageDetect === 'uzk' ? 'change__font__medium' : ''
                }
              >
                {t('contest.linkJoin')}
              </Link>
              <Link
                to={`/Tender`}
                style={{
                  color: '#00deff',
                  background: 'transparent',
                  border: '1px solid #00deff',
                  margin: '0 0 0 14px',
                }}
                className={
                  languageDetect === 'uzk' ? 'change__font__medium' : ''
                }
              >
                {t('common.details')}
              </Link>
            </div>
          </div>
          <div className="main__competition-right">
            <div className="main__guys">
              <img src={guys} alt="" data-aos="zoom-in" data-aos-offset="300" />
            </div>
            <div className="timer__wrapper">
              <div className="timer__title">
                {activeDates &&
                dayjs(new Date()).isBefore(dayjs(activeDates[0].start))
                  ? t('contest.timerTitleBefore')
                  : t('contest.timerTitle')}
              </div>
              <Time activeDate={deadlineDate} />
            </div>
          </div>
        </div>
        <img src={moon} className="moon" alt="" />
        <img
          src={stouns}
          className="stouns js-parallaxCloud"
          data-depth-x="0.15"
          alt=""
        />
        <img src={mountains} className="mountains" alt="" />
        <img src={steam} className="steam" alt="" />
        <img src={bg} className="bg" alt="" />
        <img src={imgbg} className="imgbg" alt="" />
      </div>
      <div className="main__body ">
        <div className="main__winners">
          <div
            className={`main__winners-title ${
              languageDetect === 'uzk' ? 'change__font__bold' : ''
            }`}
          >
            {t('contest.winnersTitle')}
          </div>
          <Tabs
            weekActive={weekActive}
            setweekActive={setweekActive}
            setDynamicURL={setDynamicURL}
            deadlineType={deadlineType}
            activatedWeeks={activatedWeeks}
          />
          {!isLoading ? (
            <div className="main__winners-places center-block">
              <div className="main__winners-places-top">
                <div className="main__winners-places-left">
                  <div className="main__winners-place">
                    <div className="main__winners-place-img">
                      <img src={winner} alt="" />
                      <div className="main__winners-place-img-text">
                        <img src={place2} alt="" />
                        <span>{t('contest.place')}</span>
                      </div>
                    </div>
                    {users && users.length !== 0 ? (
                      <div className="main__winners-place-id">{`ID ${
                        users[1]?.user_id ? users[1]?.user_id : ''
                      }`}</div>
                    ) : (
                      ''
                    )}
                    {users && users.length !== 0 ? (
                      <div className="main__winners-place-id">{`${
                        users[1]?.count ? users[1]?.count : ''
                      } ${t('common.points')}`}</div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="main__winners-place first">
                    <div className="main__winners-place-img">
                      <img src={winner} alt="" />
                      <div className="main__winners-place-img-text">
                        <img src={place1} alt="" />
                        <span>{t('contest.place')}</span>
                      </div>
                    </div>
                    {users && users.length !== 0 ? (
                      <div className="main__winners-place-id">{`ID ${
                        users[0]?.user_id ? users[0]?.user_id : ''
                      }`}</div>
                    ) : (
                      ''
                    )}
                    {users && users.length !== 0 ? (
                      <div className="main__winners-place-id">{`${
                        users[0]?.count ? users[0]?.count : ''
                      } ${t('common.points')}`}</div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="main__winners-place third">
                  <div className="main__winners-place-img">
                    <img src={winner} alt="" />
                    <div className="main__winners-place-img-text">
                      <img src={place3} alt="" />
                      <span>{t('contest.place')}</span>
                    </div>
                  </div>
                  {users && users.length !== 0 ? (
                    <div className="main__winners-place-id">{`ID ${
                      users[2]?.user_id ? users[2]?.user_id : ''
                    }`}</div>
                  ) : (
                    ''
                  )}
                  {users && users.length !== 0 ? (
                    <div className="main__winners-place-id">{`${
                      users[2]?.count ? users[2]?.count : ''
                    } ${t('common.points')}`}</div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="main__winners-places-bot">
                <table className="main__winners-list">
                  <thead>
                    {users && users.length !== 0 ? (
                      <tr>
                        <td>№</td>
                        <td>{`${t('common.number')} ID`}</td>
                        <td>{`${t('common.amount')}`}</td>
                      </tr>
                    ) : (
                      <tr></tr>
                    )}
                  </thead>
                  <tbody>
                    {users &&
                      users.map((n: any, index: number) => {
                        if (index >= 3 && index < 10)
                          return (
                            <tr key={n.user_id}>
                              <td>{index + 1}</td>
                              <td>{`ID ${n.user_id}`}</td>
                              <td>{n.count}</td>
                            </tr>
                          )
                        return <tr key={n.user_id}></tr>
                      })}
                  </tbody>
                </table>
                {users && users.length !== 0 ? (
                  <div className="main__winners-link">
                    <Link to="/List">{t('contest.seeUsers')}</Link>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '30px',
              }}
            >
              <div className="loader"></div>
            </div>
          )}
        </div>
        <div className="main__card center-block">
          <div
            className={`main__card-title ${
              languageDetect === 'uzk' ? 'change__font__bold' : ''
            }`}
            dangerouslySetInnerHTML={{
              __html: t('card.title'),
            }}
          ></div>
          <div className="main__card-inner">
            <div className="main__card-items">
              <div className="main__card-item">
                <div className="main__card-item-img">
                  <img
                    src={card1}
                    alt="card1"
                    data-aos="fade-up"
                    data-aos-duration="200"
                    data-aos-offset="300"
                  />
                </div>
                <div
                  className="main__card-item-text"
                  dangerouslySetInnerHTML={{
                    __html: t('card.walet'),
                  }}
                ></div>
              </div>
              <div className="main__card-item">
                <div className="main__card-item-img">
                  <img
                    src={card2}
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration="400"
                    data-aos-offset="300"
                  />
                </div>
                <div
                  className="main__card-item-text"
                  dangerouslySetInnerHTML={{
                    __html: t('card.pass'),
                  }}
                ></div>
              </div>
              <div className="main__card-item">
                <div className="main__card-item-img">
                  <img
                    src={card3}
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration="600"
                    data-aos-offset="300"
                  />
                </div>
                <div
                  className="main__card-item-text"
                  dangerouslySetInnerHTML={{
                    __html: t('card.qr'),
                  }}
                ></div>
              </div>
              <div className="main__card-item">
                <div className="main__card-item-img">
                  <img
                    src={card4}
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-offset="300"
                  />
                </div>
                <div
                  className="main__card-item-text"
                  dangerouslySetInnerHTML={{
                    __html: t('card.payment'),
                  }}
                ></div>
              </div>
              <div className="main__card-item">
                <div className="main__card-item-img">
                  <img
                    src={card5}
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-offset="300"
                  />
                </div>
                <div
                  className="main__card-item-text"
                  dangerouslySetInnerHTML={{
                    __html: t('card.earn'),
                  }}
                ></div>
              </div>
              <div className="main__card-item">
                <div className="main__card-item-img">
                  <img
                    src={card6}
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-offset="300"
                  />
                </div>
                <div
                  className="main__card-item-text"
                  dangerouslySetInnerHTML={{
                    __html: t('card.play'),
                  }}
                ></div>
              </div>
            </div>
          </div>
          <img src={cloudTop} alt="" className="cloud js-parallax" />
        </div>
        <div className="main__articles center-block top">
          <div className="main__article">
            <div className="main__article-info">
              <div
                className={`main__article-info-title ${
                  languageDetect === 'uzk' ? 'change__font__bold' : ''
                }`}
                dangerouslySetInnerHTML={{
                  __html: t('articles.start'),
                }}
              ></div>
              <div className="main__article-info-subtitle">
                {t('articles.startText')}
              </div>
            </div>
            <div className="main__article-img">
              <img
                src={
                  i18n.language === 'ru'
                    ? phone1
                    : i18n.language === 'en'
                    ? phone1en
                    : i18n.language === 'uz'
                    ? phone1oz
                    : i18n.language === 'uzk'
                    ? phone1uz
                    : phone1
                }
                alt=""
              />
            </div>
          </div>
          <div className="main__article">
            <div className="main__article-info">
              <div
                className={`main__article-info-title ${
                  languageDetect === 'uzk' ? 'change__font__bold' : ''
                }`}
                dangerouslySetInnerHTML={{
                  __html: t('articles.pass'),
                }}
              ></div>
              <div className="main__article-info-subtitle">
                {t('articles.passText')}
              </div>
            </div>
            <div className="main__article-img">
              <img
                src={
                  i18n.language === 'ru'
                    ? phone2
                    : i18n.language === 'en'
                    ? phone2en
                    : i18n.language === 'uz'
                    ? phone2oz
                    : i18n.language === 'uzk'
                    ? phone2uz
                    : phone2
                }
                alt=""
              />
            </div>
          </div>
          <img src={stars} alt="" className="stars1 js-parallax" />
          <img src={cloud1} alt="" className="cloud1 js-parallax" />
          <img src={stars} alt="" className="stars2 js-parallax" />
          <img src={cloud2} alt="" className="cloud2 js-parallax" />
        </div>
        <div className="main__banner-wrapper center-block">
          <div className="main__banner ">
            <div className="main__banner-info">
              <div
                className={`main__banner-info-text ${
                  languageDetect === 'uzk' ? 'change__font__bold' : ''
                }`}
              >
                {t('common.play')}
              </div>
              <div className="main__banner-info-links">
                <a
                  href="https://apps.apple.com/uz/app/click-start/id1590997023"
                  className="main__banner-info-link"
                  target="blank"
                >
                  <div className="main__banner-info-link-img">
                    <img src={apple2} alt="" />
                  </div>
                  <div className="main__banner-info-link-text">
                    <div>{t('common.available')}</div>
                    <div>App Store</div>
                  </div>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.click.clickstart&hl=en&gl=US"
                  className="main__banner-info-link"
                  target="blank"
                >
                  <div className="main__banner-info-link-img">
                    <img src={google} alt="" />
                  </div>
                  <div className="main__banner-info-link-text">
                    <div>{t('common.available')}</div>
                    <div>Google Play</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="main__banner-img">
              <img src={gift} alt="" />
            </div>
          </div>
        </div>
        <div className="main__articles center-block bot">
          <div className="main__article">
            <div className="main__article-info">
              <div
                className={`main__article-info-title ${
                  languageDetect === 'uzk' ? 'change__font__bold' : ''
                }`}
                dangerouslySetInnerHTML={{
                  __html: t('articles.qr'),
                }}
              ></div>
              <div className="main__article-info-subtitle">
                {t('articles.qrText')}
              </div>
            </div>
            <div className="main__article-img">
              <img
                src={
                  i18n.language === 'ru'
                    ? phone3
                    : i18n.language === 'en'
                    ? phone3en
                    : i18n.language === 'uz'
                    ? phone3oz
                    : i18n.language === 'uzk'
                    ? phone3uz
                    : phone3
                }
                alt=""
              />
            </div>
          </div>
          <div className="main__article">
            <div className="main__article-info">
              <div
                className={`main__article-info-title ${
                  languageDetect === 'uzk' ? 'change__font__bold' : ''
                }`}
                dangerouslySetInnerHTML={{
                  __html: t('articles.payment'),
                }}
              ></div>
              <div className="main__article-info-subtitle">
                {t('articles.paymentText')}
              </div>
            </div>
            <div className="main__article-img">
              <img
                src={
                  i18n.language === 'ru'
                    ? phone4
                    : i18n.language === 'en'
                    ? phone4en
                    : i18n.language === 'uzk'
                    ? phone4uz
                    : i18n.language === 'uz'
                    ? phone4oz
                    : phone4
                }
                alt=""
              />
            </div>
          </div>
          <img src={cloud1} alt="" className="cloud1 js-parallaxCloud" />
          <img src={rock} alt="" className="rock js-parallax" />
        </div>
        <Footer />
      </div>
      <Particles options={options} init={customInit} className="particles " />
    </div>
  )
}

export default Main
