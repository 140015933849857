import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from 'src/components/Header'
import apple from '../../resources/images/apple.svg'
import google from '../../resources/images/google.svg'
import guys from '../../resources/images/guys.png'
import arr1 from '../../resources/images/competition/1arr.svg'
import arr3 from '../../resources/images/competition/3arr.svg'
import arr5 from '../../resources/images/competition/5arr.svg'
import arr8 from '../../resources/images/competition/8arr.svg'
import week1 from '../../resources/images/competition/1week.png'
import week2 from '../../resources/images/competition/2week.png'
import week3 from '../../resources/images/competition/3week.png'
import week4 from '../../resources/images/competition/4week.png'
import week5 from '../../resources/images/competition/5week.png'
import week6 from '../../resources/images/competition/6week.png'
import week8 from '../../resources/images/competition/8week.png'
import girl from '../../resources/images/competition/girl.png'

import phone from '../../resources/images/competition/gifts/2-1.png'
import laptop from '../../resources/images/competition/gifts/1-1.png'
import watch from '../../resources/images/competition/gifts/3-1.png'
import cup from '../../resources/images/competition/gifts/1-4.png'
import tshirt from '../../resources/images/competition/gifts/1-2.png'
import money1 from '../../resources/images/competition/gifts/2-2.png'
import money5 from '../../resources/images/competition/gifts/3-2.png'
import card from '../../resources/images/competition/gifts/1-3.png'
import Tabs from 'src/components/Tabs'
import axios from 'axios'
import dayjs from 'dayjs'

const Сompetition = ({ users }: any) => {
  const { t, i18n } = useTranslation()
  const [weekActive, setweekActive] = useState(-1)

  const [languageDetect, setlanguageDetect] = useState<string>('')

  const [activeDates, setactiveDates] = useState<any>(null)
  const [deadlineType, setDeadlineType] = useState<string | null>(null)
  const [, setDynamicURL] = useState<string>('share')
  const [activatedWeeks, setActivatedWeeks] = useState([])
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    if (activeDates === null) {
      setisLoading(true)
      axios
        .get(`https://api.click.uz/start/challenge/api/leaderboard/schedules`)
        .then((response) => {
          setactiveDates(response.data.data)
          setisLoading(false)
        })
    }
  }, [activeDates])

  useEffect(() => {
    if (deadlineType !== null) setDeadlineType(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekActive])

  useEffect(() => {
    if (activeDates) {
      let closestType: string | null = null
      const nowDate = dayjs(new Date())
      const activetedWeeksArray: any = []

      if (nowDate.isBefore(dayjs(activeDates[0].start))) {
        closestType = activeDates[0].route
        setweekActive(1)
      } else {
        activeDates?.forEach((n: any) => {
          if (
            nowDate.isAfter(dayjs(n.start)) &&
            nowDate.isBefore(dayjs(n.end))
          ) {
            closestType = n.route
          }
          if (nowDate.isAfter(dayjs(n.start))) {
            activetedWeeksArray.push(n.route)
          }
        })
      }
      setDeadlineType(closestType)
      setActivatedWeeks(activetedWeeksArray)
    }
  }, [activeDates])

  useEffect(() => {
    if (i18n.language) {
      setlanguageDetect(i18n.language)
    }
  }, [i18n.language])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="competition">
      <div className="competition__top">
        <Header />
        <div className="competition__top-inner center-block">
          <div
            className={`competition__top-inner-title ${
              languageDetect === 'uzk' ? 'change__font__medium' : ''
            }`}
            dangerouslySetInnerHTML={{
              __html: t('competition.title'),
            }}
          ></div>
          <div
            className="main__top-links"
            style={{ justifyContent: 'flex-start' }}
          >
            <a
              href="https://apps.apple.com/uz/app/click-start/id1590997023"
              className="main__top-link"
              target="blank"
            >
              <div className="main__top-link-img">
                <img src={apple} alt="" />
              </div>
              <div className="main__top-link-text">
                <span>{t('common.available')}</span>
                <span>App Store</span>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.click.clickstart&hl=en&gl=US"
              className="main__top-link"
              target="blank"
            >
              <div className="main__top-link-img">
                <img src={google} alt="" />
              </div>
              <div className="main__top-link-text">
                <span>{t('common.available')}</span>
                <span>Google Play</span>
              </div>
            </a>
          </div>
          <div className="competition__top-inner-img">
            <img src={guys} alt="" />
          </div>
        </div>
      </div>
      <div className="competition__body">
        <div className="competition__terms">
          <div
            className={`competition__terms-title ${
              languageDetect === 'uzk' ? 'change__font__medium' : ''
            }`}
            dangerouslySetInnerHTML={{
              __html: t('competition.title2'),
            }}
          ></div>
          <Tabs
            weekActive={weekActive}
            setweekActive={setweekActive}
            deadlineType={deadlineType}
            setDynamicURL={setDynamicURL}
            activatedWeeks={activatedWeeks}
          />
          {!isLoading ? (
            <div className="competition__terms__weeks center-block">
              <div
                className={`competition__terms__week ${
                  weekActive === 1 || deadlineType === '/share' ? 'active' : ''
                }`}
              >
                <div className="competition__terms__week-left">
                  <div
                    className={`competition__terms__week-title ${
                      languageDetect === 'uzk' ? 'change__font__bold' : ''
                    }`}
                  >
                    <span>{`01 ${t('contest.week')}:`}</span>
                    <span>{t('share')}</span>
                  </div>
                  <div
                    className={`competition__terms__week-subtitle ${
                      languageDetect === 'uzk' ? 'change__font__medium' : ''
                    }`}
                  >
                    {t('weeks.week1.title')}
                  </div>
                </div>
                <div className="competition__terms__week-right">
                  <div className="competition__terms__week-screenshot">
                    <div className="competition__terms__week-screenshot-content">
                      <div
                        className={`competition__terms__week-screenshot-text ${
                          languageDetect === 'uzk' ? 'change__font__medium' : ''
                        }`}
                      >
                        {t('weeks.week1.subtitle')}
                      </div>
                      <img
                        src={arr1}
                        alt=""
                        className="competition__terms__week-screenshot-arrow"
                      />
                    </div>
                    <div className="competition__terms__week-screenshot-img">
                      <img src={week1} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`competition__terms__week ${
                  weekActive === 2 || deadlineType === '/invite' ? 'active' : ''
                }`}
              >
                <div className="competition__terms__week-left">
                  <div
                    className={`competition__terms__week-title ${
                      languageDetect === 'uzk' ? 'change__font__bold' : ''
                    }`}
                  >
                    <span>{`02 ${t('contest.week')}:`}</span>
                    <span>{t('invite')}</span>
                  </div>
                  <div
                    className={`competition__terms__week-subtitle ${
                      languageDetect === 'uzk' ? 'change__font__medium' : ''
                    }`}
                  >
                    {t('weeks.week2.title')}
                  </div>
                </div>
                <div className="competition__terms__week-right">
                  <div className="competition__terms__week-screenshot">
                    <div className="competition__terms__week-screenshot-content">
                      <div
                        className={`competition__terms__week-screenshot-text ${
                          languageDetect === 'uzk' ? 'change__font__medium' : ''
                        }`}
                      >
                        {t('weeks.week2.subtitle')}
                      </div>
                      <img
                        src={arr8}
                        alt=""
                        className="competition__terms__week-screenshot-arrow"
                      />
                    </div>
                    <div className="competition__terms__week-screenshot-img">
                      <img src={week2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`competition__terms__week ${
                  weekActive === 3 || deadlineType === '/payment/count'
                    ? 'active'
                    : ''
                }`}
              >
                <div className="competition__terms__week-left">
                  <div
                    className={`competition__terms__week-title ${
                      languageDetect === 'uzk' ? 'change__font__bold' : ''
                    }`}
                  >
                    <span>{`03 ${t('contest.week')}:`}</span>
                    <span>{t('paymentCount')}</span>
                  </div>
                  <div
                    className={`competition__terms__week-subtitle ${
                      languageDetect === 'uzk' ? 'change__font__medium' : ''
                    }`}
                  >
                    {t('weeks.week3.title')}
                  </div>
                </div>
                <div className="competition__terms__week-right">
                  <div className="competition__terms__week-screenshot">
                    <div className="competition__terms__week-screenshot-content">
                      <div
                        className={`competition__terms__week-screenshot-text ${
                          languageDetect === 'uzk' ? 'change__font__medium' : ''
                        }`}
                      >
                        {t('weeks.week3.subtitle')}
                      </div>
                      <img
                        src={arr3}
                        alt=""
                        className="competition__terms__week-screenshot-arrow"
                      />
                    </div>
                    <div className="competition__terms__week-screenshot-img bigger">
                      <img src={week3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`competition__terms__week ${
                  weekActive === 4 || deadlineType === '/payment/sum'
                    ? 'active'
                    : ''
                }`}
              >
                <div className="competition__terms__week-left">
                  <div
                    className={`competition__terms__week-title ${
                      languageDetect === 'uzk' ? 'change__font__bold' : ''
                    }`}
                  >
                    <span>{`04 ${t('contest.week')}:`}</span>
                    <span>{t('paymentSum')}</span>
                  </div>
                  <div
                    className={`competition__terms__week-subtitle ${
                      languageDetect === 'uzk' ? 'change__font__medium' : ''
                    }`}
                  >
                    {t('weeks.week4.title')}
                  </div>
                </div>
                <div className="competition__terms__week-right">
                  <div className="competition__terms__week-screenshot">
                    <div className="competition__terms__week-screenshot-content">
                      <div
                        className={`competition__terms__week-screenshot-text ${
                          languageDetect === 'uzk' ? 'change__font__medium' : ''
                        }`}
                      >
                        {t('weeks.week4.subtitle')}
                      </div>
                      <img
                        src={arr8}
                        alt=""
                        className="competition__terms__week-screenshot-arrow"
                      />
                    </div>
                    <div className="competition__terms__week-screenshot-img">
                      <img src={week4} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`competition__terms__week ${
                  weekActive === 5 || deadlineType === '/receive/count'
                    ? 'active'
                    : ''
                }`}
              >
                <div className="competition__terms__week-left">
                  <div
                    className={`competition__terms__week-title ${
                      languageDetect === 'uzk' ? 'change__font__bold' : ''
                    }`}
                  >
                    <span>{`05 ${t('contest.week')}:`}</span>
                    <span>{t('receiveCount')}</span>
                  </div>
                  <div
                    className={`competition__terms__week-subtitle ${
                      languageDetect === 'uzk' ? 'change__font__medium' : ''
                    }`}
                  >
                    {t('weeks.week5.title')}
                  </div>
                </div>
                <div className="competition__terms__week-right">
                  <div className="competition__terms__week-screenshot">
                    <div className="competition__terms__week-screenshot-content">
                      <div
                        className={`competition__terms__week-screenshot-text ${
                          languageDetect === 'uzk' ? 'change__font__medium' : ''
                        }`}
                      >
                        {t('weeks.week5.subtitle')}
                      </div>
                      <img
                        src={arr5}
                        alt=""
                        className="competition__terms__week-screenshot-arrow"
                      />
                    </div>
                    <div className="competition__terms__week-screenshot-img">
                      <img src={week5} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`competition__terms__week ${
                  weekActive === 6 || deadlineType === '/receive/sum'
                    ? 'active'
                    : ''
                }`}
              >
                <div className="competition__terms__week-left">
                  <div
                    className={`competition__terms__week-title ${
                      languageDetect === 'uzk' ? 'change__font__bold' : ''
                    }`}
                  >
                    <span>{`06 ${t('contest.week')}:`}</span>
                    <span>{t('receiveSum')}</span>
                  </div>
                  <div
                    className={`competition__terms__week-subtitle ${
                      languageDetect === 'uzk' ? 'change__font__medium' : ''
                    }`}
                  >
                    {t('weeks.week6.title')}
                  </div>
                </div>
                <div className="competition__terms__week-right">
                  <div className="competition__terms__week-screenshot">
                    <div className="competition__terms__week-screenshot-content">
                      <div
                        className={`competition__terms__week-screenshot-text ${
                          languageDetect === 'uzk' ? 'change__font__medium' : ''
                        }`}
                      >
                        {t('weeks.week6.subtitle')}
                      </div>
                      <img
                        src={arr8}
                        alt=""
                        className="competition__terms__week-screenshot-arrow"
                      />
                    </div>
                    <div className="competition__terms__week-screenshot-img">
                      <img src={week6} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`competition__terms__week ${
                  weekActive === 7 || deadlineType === '/remit/count'
                    ? 'active'
                    : ''
                }`}
              >
                <div className="competition__terms__week-left">
                  <div
                    className={`competition__terms__week-title ${
                      languageDetect === 'uzk' ? 'change__font__bold' : ''
                    }`}
                  >
                    <span>{`07 ${t('contest.week')}:`}</span>
                    <span>{t('remitCount')}</span>
                  </div>
                  <div
                    className={`competition__terms__week-subtitle ${
                      languageDetect === 'uzk' ? 'change__font__medium' : ''
                    }`}
                  >
                    {t('weeks.week7.title')}
                  </div>
                </div>
                <div className="competition__terms__week-right">
                  <div className="competition__terms__week-screenshot">
                    <div className="competition__terms__week-screenshot-content">
                      <div
                        className={`competition__terms__week-screenshot-text ${
                          languageDetect === 'uzk' ? 'change__font__medium' : ''
                        }`}
                      >
                        {t('weeks.week7.subtitle')}
                      </div>
                      <img
                        src={arr3}
                        alt=""
                        className="competition__terms__week-screenshot-arrow"
                      />
                    </div>
                    <div className="competition__terms__week-screenshot-img bigger">
                      <img src={week3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`competition__terms__week ${
                  weekActive === 8 || deadlineType === '/remit/sum'
                    ? 'active'
                    : ''
                }`}
              >
                <div className="competition__terms__week-left">
                  <div
                    className={`competition__terms__week-title ${
                      languageDetect === 'uzk' ? 'change__font__bold' : ''
                    }`}
                  >
                    <span>{`08 ${t('contest.week')}:`}</span>
                    <span>{t('remitSum')}</span>
                  </div>
                  <div
                    className={`competition__terms__week-subtitle ${
                      languageDetect === 'uzk' ? 'change__font__medium' : ''
                    }`}
                  >
                    {t('weeks.week8.title')}
                  </div>
                </div>
                <div className="competition__terms__week-right">
                  <div className="competition__terms__week-screenshot">
                    <div className="competition__terms__week-screenshot-content">
                      <div
                        className={`competition__terms__week-screenshot-text ${
                          languageDetect === 'uzk' ? 'change__font__medium' : ''
                        }`}
                      >
                        {t('weeks.week8.subtitle')}
                      </div>
                      <img
                        src={arr8}
                        alt=""
                        className="competition__terms__week-screenshot-arrow"
                      />
                    </div>
                    <div className="competition__terms__week-screenshot-img">
                      <img src={week8} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '30px',
              }}
            >
              <div className="loader"></div>
            </div>
          )}
        </div>
        <div className="competition__prizes center-block">
          <div
            className={`competition__prizes-title ${
              languageDetect === 'uzk' ? 'change__font__bold' : ''
            }`}
            dangerouslySetInnerHTML={{
              __html: t('competition.title3'),
            }}
          ></div>
          <div className="competition__prizes-articles">
            <div className="competition__prizes-article">
              <div className="competition__prizes-article-items">
                <div className="competition__prizes-article-item">
                  <img src={laptop} alt="" />
                </div>
                <div className="competition__prizes-article-item">
                  <img src={tshirt} alt="" />
                </div>
                <div className="competition__prizes-article-item">
                  <img src={card} alt="" />
                </div>
                <div className="competition__prizes-article-item">
                  <img src={cup} alt="" />
                </div>
              </div>
              <div className="competition__prizes-article-title">{`1 ${t(
                'contest.place',
              )}`}</div>
              <div
                className={`competition__prizes-article-subtitle ${
                  languageDetect === 'uzk' ? 'change__font__medium' : ''
                }`}
              >
                {t('competition.place1')}
              </div>
            </div>
            <div className="competition__prizes-article">
              <div className="competition__prizes-article-items second">
                <div className="competition__prizes-article-items-double">
                  <div className="competition__prizes-article-item">
                    <img src={phone} alt="" />
                  </div>
                  <div className="competition__prizes-article-item">
                    <img src={money1} alt="" />
                  </div>
                </div>
                <div className="competition__prizes-article-items-triple">
                  <div className="competition__prizes-article-item">
                    <img src={tshirt} alt="" />
                  </div>
                  <div className="competition__prizes-article-item">
                    <img src={cup} alt="" />
                  </div>
                  <div className="competition__prizes-article-item">
                    <img src={card} alt="" />
                  </div>
                </div>
              </div>
              <div className="competition__prizes-article-title">{`2 ${t(
                'contest.place',
              )}`}</div>
              <div
                className={`competition__prizes-article-subtitle ${
                  languageDetect === 'uzk' ? 'change__font__medium' : ''
                }`}
              >
                {t('competition.place2')}
              </div>
            </div>
          </div>
          <div className="competition__prizes-articles">
            <div className="competition__prizes-article">
              <div className="competition__prizes-article-items">
                <div className="competition__prizes-article-items-double">
                  <div className="competition__prizes-article-item">
                    <img src={watch} alt="" />
                  </div>
                  <div className="competition__prizes-article-item">
                    <img src={money5} alt="" />
                  </div>
                </div>
                <div className="competition__prizes-article-items-triple">
                  <div className="competition__prizes-article-item">
                    <img src={tshirt} alt="" />
                  </div>
                  <div className="competition__prizes-article-item">
                    <img src={cup} alt="" />
                  </div>
                  <div className="competition__prizes-article-item">
                    <img src={card} alt="" />
                  </div>
                </div>
              </div>
              <div className="competition__prizes-article-title">{`3 ${t(
                'contest.place',
              )}`}</div>
              <div
                className={`competition__prizes-article-subtitle ${
                  languageDetect === 'uzk' ? 'change__font__medium' : ''
                }`}
              >
                {t('competition.place3')}
              </div>
            </div>
            <div className="competition__prizes-article">
              <div className="competition__prizes-article-items">
                <div className="competition__prizes-article-items-fourth">
                  <div className="competition__prizes-article-item">
                    <img src={tshirt} alt="" />
                  </div>
                  <div className="competition__prizes-article-item">
                    <img src={cup} alt="" />
                  </div>
                </div>
              </div>
              <div className="competition__prizes-article-title">{`4-5 ${t(
                'contest.places',
              )}`}</div>
              <div
                className={`competition__prizes-article-subtitle ${
                  languageDetect === 'uzk' ? 'change__font__medium' : ''
                }`}
              >
                {t('competition.place4')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="competition__top competition__footer">
        <div className="competition__top-inner center-block">
          <div
            className="competition__top-inner-title"
            dangerouslySetInnerHTML={{
              __html: t('competition.title4'),
            }}
          ></div>
          <div
            className="main__top-links"
            style={{ justifyContent: 'flex-start' }}
          >
            <a
              href="https://apps.apple.com/uz/app/click-start/id1590997023"
              className="main__top-link"
              target="blank"
            >
              <div className="main__top-link-img">
                <img src={apple} alt="" />
              </div>
              <div className="main__top-link-text">
                <span>{t('common.available')}</span>
                <span>App Store</span>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.click.clickstart&hl=en&gl=US"
              className="main__top-link"
              target="blank"
            >
              <div className="main__top-link-img">
                <img src={google} alt="" />
              </div>
              <div className="main__top-link-text">
                <span>{t('common.available')}</span>
                <span>Google Play</span>
              </div>
            </a>
          </div>
          <div className="competition__top-inner-img girl">
            <img src={girl} alt="" />
          </div>
          <div
            className={`competition__copyright ${
              languageDetect === 'uzk' ? 'change__font__medium' : ''
            }`}
          >
            Copyright &copy; {new Date().getFullYear()} Click Start Contest.
            {t('common.rights')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Сompetition
