//eslint-disable-next-line
export default {
  common: {
    soon: `<div>Grand battle</div>
    starts soon!`,
    available: 'Available in',
    play: 'Play Click Start and win valuable prizes!',
    download: 'Download the Click Start app for iOS and Android!',
    rights: ' All rights reserved',
    number: 'Number',
    amount: 'Amount',
    points: 'points',
    details: 'View the offer',
  },
  navigation: {
    mainFunc: 'Main functions',
    about: 'About Clicks',
    game: 'Click Start Game',
  },
  card: {
    title: '<span>Quick Start </span> Convenient Start',
    walet: 'Personal <span>wallet</span>',
    pass: '<span>Click Pass</span> always at hand',
    qr: 'Transfers by <span>QR codes</span>',
    payment: 'Convenient <span>payment on the spot</span>',
    earn: 'Earn <span>Clicks</span>',
    play: 'Play the game <span>Click Start</span>',
  },
  articles: {
    start: 'Save <span>Spend</span> Transfer',
    startText:
      'Pay by yourself without cash or bank card. Save money in Click-Wallet. Transfer or receive money to your virtual wallet. Download application and the wallet will be opened automatically after registration.',
    pass: 'Payments via <span>Click Pass</span> or <span>QR-code</span> Always at hand',
    passText:
      'You can pay in any store where there is a payment via Click Pass or QR-code. No need to count change or carry cash.',
    qr: 'Send <span>QR-code</span> Receive money transfer',
    qrText:
      'Manage money by yourself – transfer money to friends, accept money from relatives and parents – all you need to do is show or send your QR code to a sender. Transfers to Click Start wallet with 0% commission!',
    payment: 'Transfers with no boundaries',
    paymentText:
      'Transfer and receive money in just couple of Clicks! To request some money from parents, press "Reques“ button and select the desired contact. The whole process takes less than a minute. With the new feature, you will never run out of money!',
  },

  contest: {
    title: 'Take part in our <span>contest</span>',
    subtitle:
      'You can see all the details and conditions of the contest on the Click Start Contest page! Go right now to have time to take part!',
    linkJoin: 'Join',
    timerTitle: 'BEFORE COMPETITION ENDS',
    timerTitleBefore: 'BEFORE COMPETITION STARTS',
    days: 'Days',
    hours: 'Hours',
    minuts: 'Minutes',
    seconds: 'Seconds',
    winnersTitle: 'Our winners',
    week: 'week',
    place: 'place',
    places: 'places',
    seeUsers: 'View all participants',
  },
  competition: {
    title: 'Solve tasks in the game and win <span>valuable prizes!</span>',
    title2: '<span>The terms of the contest</span> are simple and interesting',
    title3: '<span>The best prizes</span> are waiting for you',
    title4: '<span>Download the app</span> on iOS or Android!',
    place1: 'Laptop, branded T-shirt, branded cup, commemorative certificate',
    place2:
      'Smartphone, wallet replenishment for 100,000 soum, T-shirt, cup and commemorative certificate',
    place3:
      'Smart watch, wallet replenishment for 50,000 soum, T-shirt, cup and commemorative certificate',
    place4: 'Branded T-shirt, branded cup',
  },
  weeks: {
    week1: {
      title:
        'Inviting friends with the help of reposts of posts in social networks. networks and messengers',
      subtitle: 'Send it to your friends in Direct!',
    },
    week2: {
      title:
        'Inviting friends via a referral link, friends click on the link, download the invitation and register',
      subtitle: 'Copy and send your referral link to your friends',
    },
    week3: {
      title:
        'The number of transactions, the one who makes the most transactions gets a prize',
      subtitle: 'Make more transactions than anyone else',
    },
    week4: {
      title:
        'The number of transactions for the declared amount, for example, a larger number of transactions of 10, 20, 50, 100 thousand soum.',
      subtitle: 'Make transactions for 100 thousand soum more than anyone else',
    },
    week5: {
      title: 'The number of transfers received from wallet to wallet',
      subtitle: 'Get the largest number of transfers to your wallet!',
    },
    week6: {
      title:
        'The number of transfers received from wallet to wallet for 10, 20, 50, 100 thousand soum',
      subtitle:
        'Get transfers from wallet to wallet for 10, 20, 50, 100 thousand soum',
    },
    week7: {
      title: 'Number of transfers sent from wallet to wallet',
      subtitle: 'Make transfers from wallet to wallet more than anyone',
    },
    week8: {
      title:
        'The number of transfers sent from wallet to wallet for 10, 20, 50, 100 thousand soum',
      subtitle:
        'Make transfers from wallet to wallet more than anyone for a certain amount',
    },
  },
  list: {
    title: 'Contest participants',
  },
  share: 'Share',
  invite: 'Invite',
  paymentCount: 'Number of transactions',
  paymentSum: 'The number of transactions for the declared amount',
  receiveCount: 'Number of transfers received',
  receiveSum: 'The number of transfers received for the declared amount',
  remitCount: 'Number of transfers sent',
  remitSum: 'The number of transfers sent according to the declared amount',
}
