import React from 'react'
import { useHistory } from 'react-router-dom'
// import { Link } from "react-router-dom";
import logo from '../resources/images/logo.svg'
import Lang from './Lang'
// import { Menu, Plus } from "react-feather";
// import { useTranslation } from "react-i18next";

const Header = ({ lang }: any) => {
  const history = useHistory()
  // const [menuOpen, setMenuOpen] = useState(false);
  // const { t } = useTranslation();
  // const menuRef = useRef<HTMLDivElement>(null);

  // function handleClickOutside(event: any) {
  //   if (!menuRef.current?.contains(event.target)) {
  //     setMenuOpen(false);
  //   }
  // }

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  return (
    <>
      <div className="header center-block">
        <div className="header__inner">
          <div className="header__logo" onClick={() => history.push('/')}>
            <div className="header__logo-img">
              <img src={logo} alt="logo" />
            </div>
            <div className="header__logo-name">
              <span>click</span>
              <span>start</span>
            </div>
          </div>
          <div className="header__right">
            {/* <div className="header__nav-wrapper" ref={menuRef}>
            <Menu
              className={`header__nav-menu ${!menuOpen ? "active" : ""}`}
              onClick={() => {
                setMenuOpen(true);
              }}
            />
            <Plus
              className={`header__nav-plus ${menuOpen ? "active" : ""}`}
              onClick={() => {
                setMenuOpen(false);
              }}
            />
            <div className={`header__nav ${menuOpen ? "active" : ""}`}>
              <Link to="/">{t("navigation.mainFunc")}</Link>
              <Link to="/">{t("navigation.about")}</Link>
              <Link to="/">{t("navigation.game")}</Link>
            </div>
          </div> */}
          </div>
        </div>
      </div>
      <Lang lang={lang} />
    </>
  )
}

export default Header
