//eslint-disable-next-line
export default {
  common: {
    soon: `Скоро начнется
    <div>грандиозная битва!</div>`,
    available: 'Доступно в',
    play: 'Играй в Click Start и выигрывай ценные призы!',
    download: 'Скачай приложение Click Start для iOS и Android!',
    rights: ' Все права защищены',
    number: 'Номер',
    amount: 'Количество',
    points: 'баллов',
    details: 'Посмотреть оферту',
  },
  navigation: {
    mainFunc: 'Основные функции',
    about: 'О кликах',
    game: 'Игра Click Start',
  },
  card: {
    title: 'Быстрый Start <span>Удобный Start</span>',
    walet: 'Персональный <span>кошелек</span>',
    pass: '<span>Click Pass</span> всегда под рукой',
    qr: 'Переводы по <span>QR-кодам</span>',
    payment: 'Удобная <span>оплата на местах</span>',
    earn: 'Зарабатывай <span>клики</span>',
    play: 'Играй в игру <span>Click Start</span>',
  },
  articles: {
    start: 'Копи <span>Трать</span> Переводи',
    startText:
      'Оплачивай самостоятельно без наличных или банковской карты. Копи деньги в Click-кошельке. Переводи или получай денежные переводы на свой виртуальный кошелек. Скачай приложение и кошелек будет открыт сразу после регистрации.',
    pass: 'Оплаты через <span>Click Pass</span> и <span>QR-код</span> Всегда под рукой',
    passText:
      'Ты можешь оплачивать в любом магазине, где есть оплата через Click Pass или QR-код. Больше не нужно считать сдачу в магазине или носить с собой наличные.',
    qr: 'Отправляй <span>QR-код</span> Получай денежный перевод ',
    qrText:
      'Распоряжайся деньгами самостоятельно – переводи друзьям, принимай деньги от родных и близких – для этого достаточно показать или отправить свой QR код отправителю. Переводы на кошелек Click Start с комиссией 0%! ',
    payment: 'Переводы без границ',
    paymentText:
      'Переводи и получай деньги всего в пару кликов! Чтобы запросить деньги у родителей, нажми на кнопку «Запросить» и выбери нужный контакт. Весь процесс занимает меньше минуты. С новой функцией ты никогда не останешься без денег!',
  },

  contest: {
    title: 'Прими участие в нашем <span>конкурсе</span>',
    subtitle:
      'Все детали и условия конкурса ты можешь посмотреть на странице Click Start Конкурс! Переходи прямо сейчас, чтобы успеть принять участие!',
    linkJoin: 'Присоединиться',
    timerTitle: 'ДО КОНЦА КОНКУРСА ОСТАЛОСЬ',
    timerTitleBefore: 'ДО НАЧАЛА КОНКУРСА ОСТАЛОСЬ',
    days: 'Дней',
    hours: 'Часов',
    minuts: 'Минут',
    seconds: 'Секунд',
    winnersTitle: 'Наши победители',
    week: 'неделя',
    place: 'место',
    places: 'места',
    seeUsers: 'Посмотреть всех участников',
  },
  competition: {
    title: 'Решай задания в игре и выигрывай <span>ценные призы!</span>',
    title2: '<span>Условия конкурса</span> простые и интересные',
    title3: '<span>Самые лучшие призы</span> ждут Вас',
    title4: '<span>Скачай приложение </span> на iOS или Android!',
    place1:
      'Ноутбук, фирменная футболка, фирменная кружка, памятный сертификат',
    place2:
      'Смартфон, пополнение кошелька на 100 000 сум, футболка, кружка и памятный сертификат',
    place3:
      'Смарт-часы, пополнение кошелька на 50 000 сум, футболка, кружка и памятный сертификат',
    place4: 'Фирменная футболка, фирменная кружка',
  },
  weeks: {
    week1: {
      title:
        'Приглашение друзей при помощи репостов постов в соц. сетях и мессенджерах',
      subtitle: 'Отправь своим друзьям в Direct!',
    },
    week2: {
      title:
        'Приглашение друзей посредством реферальной ссылки, друзья переходят по ссылке, скачивают приглашение и регистрируются',
      subtitle: 'Скопируй и отправь своим друзьям реферальную ссылку',
    },
    week3: {
      title:
        'Количество транзакций, тот, кто совершит большее количество транзакций получает приз',
      subtitle: 'Сделай больше транзакций чем кто-либо другой',
    },
    week4: {
      title:
        'Количество транзакций по заявленной сумме, например, большее количество транзакций по 10, 20, 50, 100 тыс. сум.',
      subtitle:
        'Сделайте транзакции на 100 тыс. сум больше чем кто-либо другой',
    },
    week5: {
      title: 'Количество полученных переводов с кошелька на кошелек',
      subtitle: 'Получите самое большое количество переводов на свой кошелек!',
    },
    week6: {
      title:
        'Количество полученных переводов с кошелька на кошелек по 10, 20 50, 100 тыс. сум',
      subtitle:
        'Получи переводы с кошелька на кошелек по 10, 20 50, 100 тыс. сум',
    },
    week7: {
      title: 'Количество отправленных переводов с кошелька на кошелек',
      subtitle: 'Сделай переводы с кошелька на кошелек больше чем кто-либо',
    },
    week8: {
      title:
        'Количество отправленных переводов с кошелька на кошелек по 10, 20 50, 100 тыс. сум',
      subtitle:
        'Сделай переводы с кошелька на кошелек больше чем кто-либо на определенную сумму',
    },
  },
  list: {
    title: 'Участники конкурса',
  },
  share: 'Поделиться',
  invite: 'Пригласить',
  paymentCount: 'Количество транзакций',
  paymentSum: 'Количество транзакций по заявленной сумме',
  receiveCount: 'Количество полученных переводов',
  receiveSum: 'Количество полученных переводов по заявленной сумме',
  remitCount: 'Количество отправленных переводов',
  remitSum: 'Количество отправленных переводов  по заявленной сумме',
  tender: {
    title: 'Правила акции <span>“Вершина успеха”</span>',
    period: 'Период проведения: <span>с 01.12.2022 до 26.01.2023</span>',
    deadline:
      'Дата подведения итогов: каждый четверг в течение периода проведения акции',
    organizer: 'Организатор: <span>ООО “Click”</span>.',
    place:
      'Место проведения розыгрыша: Приложение <span>Click Start</span> / Страницы <span>Click Start</span> в социальных сетях.',
    gifts: {
      place1: {
        gift1: 'Ноутбук',
        gift2: 'Свитшот с логотипом Click Start',
        gift3: 'Кружка с логотипом Click Start',
        gift4: 'Наклейки',
        gift5: 'Памятный сертификат',
      },
      place2: {
        gift1: 'Смартфон',
        gift2: 'Пополнение кошелька на 100 тыс. сумов',
        gift3: 'Свитшот с логотипом Click Start',
        gift4: 'Кружка с логотипом Click Start',
        gift5: 'Наклейки',
        gift6: 'Памятный сертификат',
      },
      place3: {
        gift1: 'Смарт-часы',
        gift2: 'Пополнение кошелька на 50 тыс. сумов',
        gift3: 'Свитшот с логотипом Click Start',
        gift4: 'Кружка с логотипом Click Start',
        gift5: 'Наклейки',
        gift6: 'Памятный сертификат',
      },
      place4: {
        title: 'Остальные призы с 4 по 10 место',
        gift1: 'Свитшоты',
        gift2: 'Кружки',
        gift3: 'Наклейки',
      },
    },
    mechanism: {
      title: 'Механизм проведения конкурса',
      subtitle:
        'Общая длительность акции составляет 8 недель. В течение указанного времени каждую неделю проводятся различные конкурсы, определяющие топ-10 победителей. Победителями становятся те участники, которые первыми выполнили все условия. Посмотреть свои результаты можно на сайте <a href="https://click-start.uz/List">https://click-start.uz/List</a>, ID участника указывается в разделе «Профиль» - «Личные данные» (Мой идентификационный номер (ID)). Пользователю дается 1 балл каждый раз, когда выполняется условие конкурса. <br /> <br />  Исключением являются конкурсы с указанными суммами платежей (4, 6, 8 недели). В этих конкурсах баллы предоставляются соответственно указанной сумме: 10 тыс. сумов – 10 тыс. баллов, 20 тыс. сумов – 20 тыс. баллов и т.д.',
      programm: 'Программа конкурсов',
      items: {
        item1: `<span>Неделя 1 – Share:</span> Пригласить как можно большее количество друзей в приложение Click Start с помощью кнопки «Поделиться» в социальных сетях и мессенджерах. Начало 1 декабря 00.01 – окончание 7 декабря 23.59.`,
        item2: `<span>Неделя 2 - Invite:</span> Пригласить как можно большее количество друзей в приложение Click Start с помощью реферальной ссылки. Уникальная реферальная ссылка пользователя находится в приложении в разделе «Профиль» - «Реферальная система».  Начало 8 декабря 00.01 – окончание 14 декабря 23.59.`,
        item3: `<span>Неделя 3 – Payment Count:</span> Совершить наибольшее количество оплат. Оплаты могут быть проведены на любую сумму, за любой сервис/товар/услугу. Начало 15 декабря 00.01 – окончание 21 декабря 23.59.`,
        item4: `<span>Неделя 4 – Payment Sum:</span> Совершить наибольшее количество оплат по указанной сумме - 10 000 сум, 20 000 сум, 50 000 сум, 100 000 сум. Оплаты должны быть проведены на вышеуказанные суммы за любой сервис/товар/услугу. Оплаты, проведенные через Click Pass не засчитываются. Начало 22 декабря 00.01 – окончание 28 декабря 23.59.`,
        item5: `<span>Неделя 5 – Receive Count:</span> Получить наибольшее количество переводов с кошелька на кошелек. Переводы должны быть получены пользователем в приложении Click Start на любую сумму от любого пользователя в приложении Click Start.  Начало 29 декабря 00.01 – окончание 4 января 23.59.`,
        item6: `<span>Неделя 6 – Receive Sum:</span> Получить наибольшее количество переводов с кошелька на кошелек по указанной сумме - 10 000 сум, 20 000 сум, 50 000 сум, 100 000 сум. Переводы должны быть получены пользователем в приложении Click Start на вышеуказанные суммы от любого пользователя в приложении Click Start. Начало 5 января декабря 00.01 – окончание 11 января 23.59.`,
        item7: `<span>Неделя 7 – Remit Count:</span> Совершить наибольшее количество переводов с кошелька на кошелек. Переводы должны быть получены пользователем в приложении Click Start на любую сумму от любого пользователя в приложении Click Start. Начало 12 января 00.01 – окончание 18 января 23.59.`,
        item8: `<span>Неделя 8 – Remit Sum:</span> Совершить наибольшее количество переводов с кошелька на кошелек по указанной сумме - 10 000 сум, 20 000 сум, 50 000 сум, 100 000 сум. Переводы должны быть получены пользователем в приложении Click Start на вышеуказанные суммы от любого пользователя в приложении Click Start.   Начало 19 января декабря 00.01 – окончание 25 января 23.59.`,
      },
    },
    competitors: {
      title: 'Участники',
      subtitle:
        'Участниками акции являются физические лица (пользователи приложения Click Start), являющиеся гражданами Республики Узбекистан.',
    },
    expired:
      'Внимание! Если Победитель отказался от получения приза или не выразил намерения получить его в течение 10 банковских дней с момента объявления результатов Акции, приз считается невостребованным, а право победителя розыгрыша на получения приза – утраченным. Победитель розыгрыша, отказавшийся от получения приза или не выразивший своего намерения получить приз в указанный срок, не вправе по истечении этого срока требовать от Организатора невостребованный приз.',
  },
}
