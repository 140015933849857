import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from 'src/components/Header'

const Tender = () => {
  const lang = true
  const { t, i18n } = useTranslation()
  const [languageDetect, setlanguageDetect] = useState<string>('')

  useEffect(() => {
    if (i18n.language) {
      setlanguageDetect(i18n.language)
    }
  }, [i18n.language])

  return (
    <div className="tender">
      <Header lang={lang} />
      <div className="tender__top center-block">
        <div
          className={`tender__top-title ${
            languageDetect === 'uzk' ? 'change__font__bold' : ''
          }`}
          dangerouslySetInnerHTML={{
            __html: t('tender.title'),
          }}
        ></div>
        <div
          className={`tender__top-period ${
            languageDetect === 'uzk' ? 'change__font__bold' : ''
          }`}
          dangerouslySetInnerHTML={{
            __html: t('tender.period'),
          }}
        ></div>
        <div
          className={`tender__top-deadline ${
            languageDetect === 'uzk' ? 'change__font__bold' : ''
          }`}
        >
          {t('tender.deadline')}
        </div>
        <div
          className={`tender__top-organizer ${
            languageDetect === 'uzk' ? 'change__font__bold' : ''
          }`}
          dangerouslySetInnerHTML={{
            __html: t('tender.organizer'),
          }}
        ></div>
        <div
          className={`tender__top-place ${
            languageDetect === 'uzk' ? 'change__font__bold' : ''
          }`}
          dangerouslySetInnerHTML={{
            __html: t('tender.place'),
          }}
        ></div>
      </div>
      <div className="tender__cards center-block">
        <div className="tender__card">
          <div className="tender__card-title">{`1 ${t('contest.place')}`}</div>
          <ul>
            <li>{t('tender.gifts.place1.gift1')}</li>
            <li>{t('tender.gifts.place1.gift2')}</li>
            <li>{t('tender.gifts.place1.gift3')}</li>
            <li>{t('tender.gifts.place1.gift4')}</li>
            <li>{t('tender.gifts.place1.gift5')}</li>
          </ul>
        </div>
        <div className="tender__card">
          <div className="tender__card-title">{`2 ${t('contest.place')}`}</div>
          <ul>
            <li>{t('tender.gifts.place2.gift1')}</li>
            <li>{t('tender.gifts.place2.gift2')}</li>
            <li>{t('tender.gifts.place2.gift3')}</li>
            <li>{t('tender.gifts.place2.gift4')}</li>
            <li>{t('tender.gifts.place2.gift5')}</li>
            <li>{t('tender.gifts.place2.gift6')}</li>
          </ul>
        </div>
        <div className="tender__card">
          <div className="tender__card-title">{`3 ${t('contest.place')}`}</div>
          <ul>
            <li>{t('tender.gifts.place3.gift1')}</li>
            <li>{t('tender.gifts.place3.gift2')}</li>
            <li>{t('tender.gifts.place3.gift3')}</li>
            <li>{t('tender.gifts.place3.gift4')}</li>
            <li>{t('tender.gifts.place3.gift5')}</li>
            <li>{t('tender.gifts.place3.gift6')}</li>
          </ul>
        </div>
        <div className="tender__card">
          <div className="tender__card-title">
            {t('tender.gifts.place4.title')}
          </div>
          <ul>
            <li>{t('tender.gifts.place4.gift1')}</li>
            <li>{t('tender.gifts.place4.gift2')}</li>
            <li>{t('tender.gifts.place4.gift3')}</li>
          </ul>
        </div>
      </div>
      <div className="tender__list center-block">
        <div
          className={`tender__list-title ${
            languageDetect === 'uzk' ? 'change__font__bold' : ''
          }`}
        >
          {t('tender.mechanism.title')}
        </div>
        <div
          className={`tender__list-subtitle ${
            languageDetect === 'uzk' ? 'change__font__bold' : ''
          }`}
          dangerouslySetInnerHTML={{
            __html: t('tender.mechanism.subtitle'),
          }}
        ></div>
        <div className="tender__list-items">
          <div className="tender__list-items-title">
            {t('tender.mechanism.programm')}
          </div>
          <div
            className="tender__list-item"
            dangerouslySetInnerHTML={{
              __html: t('tender.mechanism.items.item1'),
            }}
          ></div>
          <div
            className="tender__list-item"
            dangerouslySetInnerHTML={{
              __html: t('tender.mechanism.items.item2'),
            }}
          ></div>
          <div
            className="tender__list-item"
            dangerouslySetInnerHTML={{
              __html: t('tender.mechanism.items.item3'),
            }}
          ></div>
          <div
            className="tender__list-item"
            dangerouslySetInnerHTML={{
              __html: t('tender.mechanism.items.item4'),
            }}
          ></div>
          <div
            className="tender__list-item"
            dangerouslySetInnerHTML={{
              __html: t('tender.mechanism.items.item5'),
            }}
          ></div>
          <div
            className="tender__list-item"
            dangerouslySetInnerHTML={{
              __html: t('tender.mechanism.items.item6'),
            }}
          ></div>
          <div
            className="tender__list-item"
            dangerouslySetInnerHTML={{
              __html: t('tender.mechanism.items.item7'),
            }}
          ></div>
          <div
            className="tender__list-item"
            dangerouslySetInnerHTML={{
              __html: t('tender.mechanism.items.item8'),
            }}
          ></div>
          <div
            style={{
              color: '#fff',
              fontSize: '15px',
              lineHeight: '130%',
              paddingTop: '25px',
            }}
          >
            {t('tender.expired')}
          </div>
        </div>
      </div>
      <div className="tender__competitors ">
        <div className="tender__competitors-inner center-block">
          <div className="tender__competitors-title">
            {t('tender.competitors.title')}
          </div>
          <div className="tender__competitors-subtitle">
            {t('tender.competitors.subtitle')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tender
